



































































import { defineComponent, computed, inject } from '@vue/composition-api';
import { Scrollbar } from '@/app/components';
import WizardActions from '../../components/WizardActions.vue';
import ConceptOverviewView from './ConceptOverviewView.vue';
import { StatusCode } from '../../constants';

export default defineComponent({
    name: 'MappingReview',
    components: { Scrollbar, WizardActions, ConceptOverviewView },
    model: {
        prop: 'configuration',
    },
    props: {
        tabId: {
            type: Number,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        invalidMappingsFixed: {
            type: Boolean,
            default: false,
        },
        message: {
            type: Object,
            required: false,
        },
        mappingStatus: {
            type: String,
            default: 'configuration',
        },
    },
    setup(props) {
        const activeTab = inject('activeTab');

        const filteredFields = computed(() => {
            return props.configuration.fields.filter((obj: any) => {
                return 'target' in obj && 'id' in obj.target && obj.target.id;
            });
        });

        const unidentifiedConcepts = computed(() => {
            const concepts = props.configuration.fields.filter((obj: any) => {
                return !('target' in obj && 'id' in obj.target && obj.target.id);
            });
            const splitConcepts: any[] = [];
            let currentArray: any[] = [];
            concepts.forEach((concept: any) => {
                if (currentArray.length === 4) {
                    splitConcepts.push(currentArray);
                    currentArray = [];
                }
                currentArray.push(concept);
            });
            if (currentArray.length > 0) {
                splitConcepts.push(currentArray);
            }

            return splitConcepts;
        });

        const failedStepMessage = computed(() => (props.mappingStatus === StatusCode.Update ? props.message : null));

        return { activeTab, unidentifiedConcepts, filteredFields, failedStepMessage };
    },
});
