


























































































































































































































import * as R from 'ramda';
import { defineComponent, computed, ref, inject } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import { FormBlock, Scrollbar } from '@/app/components';
import { JobsAPI, RunnerAPI } from '../api';

extend('required', {
    ...required,
    message: 'This {_field_} is required',
});

extend('max', {
    ...max,
    message: 'Maximum length exceeded',
});

interface Step {
    id: number;
    order: number;
}

export default defineComponent({
    name: 'ConfigureJob',
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
    },
    components: { FormBlock, OrbitSpinner, ValidationObserver, ValidationProvider, Scrollbar },
    setup(props, { root }) {
        const enableOnPremiseExecution = ref(false);
        const isFeatureEnabled = inject('isEnabled');
        const jobRef = ref<any>(null);
        const isNew = computed(() => props.id === undefined);
        const actionText = computed(() => (isNew.value ? 'Create' : 'Update'));
        const id: number | null = props.id ? parseInt(`${props.id}`, 10) : null;
        const job = ref({
            name: '',
            description: '',
            runnerId: null,
            version: 'v1.0',
        });
        const stepTypes = ref<any>([]);
        const enabledSteps = ref<Step[]>([]);
        const runners = ref<any[]>([]);
        const execution = ref<string>('cloud');
        const selectedRunner = ref<any>(null);
        const { exec, loading, error } = useAxios(true);

        const runnerId = computed(() => {
            if (execution.value === 'local') {
                return selectedRunner.value;
            }
            return null;
        });

        const isOnPremise = computed(() => {
            return execution.value === 'local';
        });

        // Load job (if an id is provided) and load enabledSteps
        if (id) {
            exec(JobsAPI.get(id))
                .then((res: any) => {
                    job.value = res.data;
                    selectedRunner.value = job.value.runnerId;
                    execution.value = selectedRunner.value ? 'local' : 'cloud';
                    exec(JobsAPI.getJobSteps(id)).then((resSteps: any) => {
                        const steps = R.sort(R.ascend<any>(R.prop('order')), resSteps.data);
                        enabledSteps.value = steps.reduce((result: Step[], obj: any) => {
                            result.push({ id: obj.dataCheckinStepTypeId, order: obj.order });
                            return result;
                        }, []);
                    });
                })
                .catch((e) => {
                    if (e.response) {
                        switch (e.response.status) {
                            case 404:
                                (root as any).$toastr.e('The Data Collection Task was not found', 'Error');
                                break;
                            case 403:
                                (root as any).$toastr.e('Access to the Data Collection Task is forbidden', 'Error');
                                break;
                            default:
                                (root as any).$toastr.e('Retrieving Data Collection Task failed', 'Error');
                        }
                    }
                    root.$router.push({ name: 'data-checkin-jobs' });
                });
        }

        // Load step types and add non-optional steps to the enabledSteps
        exec(JobsAPI.getStepTypes()).then((response: any) => {
            stepTypes.value = response.data;

            // Enable mapping field and disable its button
            const mappingIdx = stepTypes.value.findIndex((step: any) => step.name === 'mapping');
            stepTypes.value[mappingIdx].isOptional = false;

            if (!id) {
                response.data.forEach((step: any) => {
                    if (!step.isOptional) {
                        enabledSteps.value.push({ id: step.id, order: step.order });
                    }
                });
            }
        });

        // Load registered runners
        exec(RunnerAPI.all()).then((response: any) => {
            runners.value = response.data;
        });

        // Methods
        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs' });
        };
        const saveChanges = async () => {
            const valid = await jobRef.value.validate();
            if (valid) {
                if (id) {
                    await exec(JobsAPI.update(id, job.value as any));
                } else {
                    const newJob: any = {
                        ...job.value,
                        enabledSteps: enabledSteps.value,
                        runnerId: runnerId.value,
                        config: {
                            version: process.env.VUE_APP_JOB_VERSION,
                        },
                    };
                    await exec(JobsAPI.create(newJob));
                }
                if (!error.value) {
                    root.$router.push({ name: 'data-checkin-jobs' });
                }
            }
        };

        const checkSteps = () => {
            stepTypes.value.forEach((type: any) => {
                if (
                    type.prerequisiteStepId &&
                    enabledSteps.value.find((enStep: any) => enStep.id === type.id) &&
                    !enabledSteps.value.find((enStep: any) => enStep.id === type.prerequisiteStepId)
                ) {
                    const idx = enabledSteps.value.findIndex((enStep: any) => enStep.id === type.id);
                    enabledSteps.value.splice(idx, 1);
                    checkSteps();
                }
            });
        };

        const checkOnPremiseSteps = () => {
            if (isOnPremise.value) {
                const mappingStep = stepTypes.value.find((step: any) => step.name === 'mapping');
                const idx = enabledSteps.value.findIndex((enStep: any) => enStep.id === mappingStep.id);
                if (idx === -1) {
                    enabledSteps.value.push({ id: mappingStep.id, order: mappingStep.order });
                }
            } else {
                const encryptionStep = stepTypes.value.find((step: any) => step.name === 'encryption');
                const idx = enabledSteps.value.findIndex((enStep: any) => enStep.id === encryptionStep.id);
                if (idx !== -1) {
                    enabledSteps.value.splice(idx, 1);
                }
            }
        };

        const isStepDisabled = (item: any) => {
            return (
                !isNew.value ||
                (item.prerequisiteStepId && !enabledSteps.value.find((step) => step.id === item.prerequisiteStepId)) ||
                item.name === 'mapping' || //  && isOnPremise.value
                (item.name === 'encryption' && !isOnPremise.value)
            );
        };

        return {
            isFeatureEnabled,
            actionText,
            cancel,
            enabledSteps,
            error,
            isNew,
            job,
            jobRef,
            loading,
            saveChanges,
            stepTypes,
            checkSteps,
            runners,
            execution,
            runnerId,
            selectedRunner,
            isOnPremise,
            checkOnPremiseSteps,
            isStepDisabled,
            enableOnPremiseExecution,
        };
    },
});
