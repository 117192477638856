





















import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { FormBlock } from '@/app/components';
import Schedule from '@/modules/apollo/components/Schedule.vue';
import { ScheduleAPI } from '@/modules/workflow-designer/api';

export default defineComponent({
    name: 'Schedules',
    components: {
        FormBlock,
        Schedule,
    },
    props: {
        workflowId: {
            type: String,
            required: true,
        },
        retrievalType: {
            type: String,
            default: 'periodic',
        },
    },
    setup(props, { root, emit }) {
        const editingSchedule = ref<boolean>(false);
        const closeScheduleEditor = ref<boolean>(false);
        const schedulesError = ref<string | null>(null);
        const loadingSchedules = ref<boolean>(false);
        const schedules = ref<any>([]);

        const getSchedules = () => {
            loadingSchedules.value = true;
            ScheduleAPI.getSchedules(props.workflowId).then((resSchedules: any) => {
                schedules.value = resSchedules.data;
                emit('updated-schedules', schedules.value);
                if (schedules.value.length) {
                    closeScheduleEditor.value = true;
                }
                loadingSchedules.value = false;
            });
        };

        const saveSchedule = async (schedule: any) => {
            schedulesError.value = null;
            if (schedule.id) {
                ScheduleAPI.update(schedule.id, schedule)
                    .then(() => {
                        getSchedules();
                        (root as any).$toastr.s('Schedule has been updated successfully', 'Success');
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    });
            } else {
                ScheduleAPI.create(schedule)
                    .then(() => {
                        (root as any).$toastr.s('Schedule has been created successfully', 'Success');
                        getSchedules();
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    });
            }
        };

        const changeScheduleStatus = (schedule: any) => {
            const idx = schedules.value.findIndex((obj: any) => obj.id === schedule.id);
            if (schedule.isEnabled) {
                ScheduleAPI.deactivate(schedule.id).then(() => {
                    schedules.value[idx].isEnabled = false;
                });
            } else {
                ScheduleAPI.activate(schedule.id).then(() => {
                    schedules.value[idx].isEnabled = true;
                });
            }
        };

        const deleteSchedule = (id: string) => {
            ScheduleAPI.delete(id).then(() => {
                const idx = schedules.value.findIndex((obj: any) => obj.id === id);
                schedules.value.splice(idx, 1);
                emit('updated-schedules', schedules.value);
            });
        };

        getSchedules();

        const scheduleRequired = computed(() => props.retrievalType !== 'immediately');

        watch(
            () => props.retrievalType,
            async (retrieval: string) => {
                if (retrieval) {
                    const promises: any = [];

                    if (schedules.value.length) {
                        schedules.value.forEach((schedule: any) => {
                            promises.push(ScheduleAPI.delete(schedule.id));
                        });

                        await Promise.all(promises);
                        schedules.value.splice(0);
                        emit('updated-schedules', schedules.value);
                    }
                }
            },
        );

        return {
            editingSchedule,
            saveSchedule,
            deleteSchedule,
            changeScheduleStatus,
            loadingSchedules,
            schedules,
            schedulesError,
            closeScheduleEditor,
            scheduleRequired,
        };
    },
});
