import { render, staticRenderFns } from "./CleaningReview.vue?vue&type=template&id=60c0469a&scoped=true&"
import script from "./CleaningReview.vue?vue&type=script&lang=ts&"
export * from "./CleaningReview.vue?vue&type=script&lang=ts&"
import style0 from "./CleaningReview.vue?vue&type=style&index=0&id=60c0469a&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c0469a",
  null
  
)

export default component.exports