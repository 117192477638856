
















































import * as R from 'ramda';
import dayjs from 'dayjs';
import { computed, defineComponent, ref } from '@vue/composition-api';
import Scrollbar from '@/app/components/Scrollbar.vue';
import { GeneralizationMethod } from '../../../constants';
import { useAnonymisation } from '../../../composable';

export default defineComponent({
    name: 'FieldPreview',
    components: {
        Scrollbar,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        numSampleValues: {
            type: Number,
            default: 5,
        },
        maxAutoLevels: {
            type: Number,
            default: 3,
        },
    },
    setup(props) {
        const { getMaxStringLength, getLevelCount, getNextLevel, getSampleValue } = useAnonymisation();
        const invalidDateFormat = ref<boolean>(false);

        const numValues = computed(() => {
            if (props.sample.length < props.numSampleValues) {
                return props.sample.length;
            }
            return props.numSampleValues;
        });

        const getIntervalValue = (value: number, level: any) => {
            const lowerBound = Math.floor(value / level.interval) * level.interval;
            return `${lowerBound}-${lowerBound + parseInt(level.interval, 10)}`;
        };

        const getNumericalGroupValue = (value: number, level: any) => {
            let label = '*';
            level.forEach((group: any) => {
                if (value >= group.from && value <= group.to) {
                    label = group.label;
                    if (!label || label.length === 0) {
                        label = `${group.from}-${group.to}`;
                    }
                }
            });
            return label;
        };

        const getMaskingValue = (value: string, level: any, maxCharacters: number) => {
            const prefix = Array(maxCharacters - value.length + 1).join(props.field.options.paddingChar);
            const temp = prefix + value;
            const slicedValue = temp.slice(0, -level);
            const suffix = Array(level + 1).join(props.field.options.maskingChar);
            return slicedValue + suffix;
        };

        // Convert a datetime string to utc datetime object and return its timezone
        const getTimezoneAndDatetime = (value: string) => {
            let timezone = value.substring(19, value.length);
            const validDate = Date.parse(value);
            if (validDate) {
                let datestr = value;
                if (timezone.length === 0) {
                    timezone = 'Z';
                    datestr = `${datestr}${timezone}`;
                }
                const datetime = new Date(datestr);
                const timezoneOffset = datetime.getTimezoneOffset();
                const utc = new Date(datetime.getTime() + timezoneOffset * 60 * 1000);
                if (timezone.length > 1) {
                    const timezoneHours = parseInt(timezone.substring(1, 3), 10);
                    if (timezone[0] === '+') utc.setHours(utc.getHours() + timezoneHours);
                    else utc.setHours(utc.getHours() - timezoneHours);
                }
                return { timezone, datetime: utc };
            }
            invalidDateFormat.value = true;
            return { timezone: null, datetime: null };
        };

        const getDatetimeValue = (value: string, level: any) => {
            const { timezone, datetime } = getTimezoneAndDatetime(value);
            if (datetime) {
                if (level >= 1) datetime.setSeconds(0);
                if (level >= 2) datetime.setMinutes(0);
                if (level >= 3) datetime.setHours(0);
                if (level >= 4) datetime.setDate(1);
                if (level >= 5) datetime.setMonth(0);
                if (level >= 6) datetime.setFullYear(datetime.getFullYear() - (datetime.getFullYear() % 10));
                const strdate = dayjs(datetime.toISOString()).format('YYYY-MM-DDTHH:mm:ss');
                return `${strdate}${timezone}`;
            }
            return null;
        };

        const getDateValue = (value: string, level: any) => {
            if (Date.parse(value)) {
                const date = new Date(value);
                if (level >= 1) date.setDate(1);
                if (level >= 2) date.setMonth(0);
                if (level >= 3) date.setFullYear(date.getFullYear() - (date.getFullYear() % 10));
                return dayjs(date.toISOString()).format('YYYY-MM-DD');
            }
            invalidDateFormat.value = true;
            return null;
        };

        const getTimeValue = (value: string, level: any) => {
            const { timezone, datetime: time } = getTimezoneAndDatetime(`1970-01-01T${value}`);
            if (time) {
                if (level >= 1) time.setSeconds(0);
                if (level >= 2) time.setMinutes(0);
                if (level >= 3) time.setHours(0);
                const strdate = dayjs(time.toISOString()).format('HH:mm:ss');
                return `${strdate}${timezone}`;
            }
            return null;
        };

        const getBooleanValue = (show: boolean) => {
            if (show) return '{true, false}';
            return '*';
        };

        const getLevelValue = (value: any, maxCharacters: number, level: any) => {
            if (value === null) return null;
            switch (props.field.generalization) {
                case GeneralizationMethod.Interval:
                    return getIntervalValue(value, level);
                case GeneralizationMethod.NumericalGroup:
                    return getNumericalGroupValue(value, level);
                case GeneralizationMethod.Masking:
                    return getMaskingValue(value, level, maxCharacters);
                case GeneralizationMethod.Datetime:
                    return getDatetimeValue(value, level);
                case GeneralizationMethod.Date:
                    return getDateValue(value, level);
                case GeneralizationMethod.Time:
                    return getTimeValue(value, level);
                case GeneralizationMethod.BooleanGroup:
                    return getBooleanValue(props.field.options.show);
                default:
                    return '*';
            }
        };

        // Replace all null values in sample with the replaceWith value
        const replaceNullValues = (sample: Array<any>) => {
            const newSample = R.clone(sample);
            if (!props.field.options.nullValues.keep) {
                for (let i = 0; i < newSample.length; i += 1) {
                    if (!newSample[i][props.field.originalName]) {
                        newSample[i][props.field.originalName] = props.field.options.nullValues.replaceWith;
                    }
                }
            }
            return newSample;
        };

        const previewTable = computed(() => {
            let sample: any = R.clone(props.sample);
            const values: any = { '0': [] };
            sample = replaceNullValues(sample);

            // The initial values before anonymisation (level 0)
            for (let i = 0; i < numValues.value; i += 1) {
                let value = getSampleValue(sample[i], props.field);
                if (R.is(Array, value)) [value] = [value[0]];
                values['0'].push(value);
            }

            const maxCharacters = getMaxStringLength(sample, props.field);
            const levelCount = getLevelCount(maxCharacters, props.maxAutoLevels, props.field);

            let level = null;
            for (let i = 1; i <= levelCount; i += 1) {
                level = getNextLevel(i, level, props.field);
                values[i] = [];
                for (let j = 0; j < numValues.value; j += 1) {
                    let value = getSampleValue(sample[j], props.field);
                    if (R.is(Array, value)) [value] = [value[0]];
                    values[i].push(getLevelValue(value, maxCharacters, level));
                }
            }
            return values;
        });

        return {
            numValues,
            previewTable,
            invalidDateFormat,
        };
    },
});
