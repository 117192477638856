






























































































import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { HtmlModal, SvgImage } from '@/app/components';

export default defineComponent({
    name: 'StepCompletionModal',
    components: { SvgImage, HtmlModal },
    props: {
        job: { type: Object, required: true },
        currentStep: { type: Object, required: true },
        nextStep: Object,
        message: String,
        restartedStep: { type: Boolean, default: false },
    },
    setup() {
        const nextStepRef = ref<any>(null);
        const backRef = ref<any>(null);

        onMounted(() => {
            if (nextStepRef.value) {
                nextStepRef.value.$el.focus();
            } else if (backRef.value) {
                backRef.value.$el.focus();
            }
        });

        return {
            nextStepRef,
            backRef,
        };
    },
});
