




























































































import { ref, watch } from '@vue/composition-api';
import { DropdownMenu } from '@/app/components';
import { useCleaning } from '../../../composable';

export default {
    name: 'ViewConstraint',
    props: {
        types: {
            type: Array,
            required: true,
        },
        constraint: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            required: true,
        },
        isFail: {
            type: Boolean,
            default: null,
        },
        hasStats: {
            type: Boolean,
            default: false,
        },
    },
    components: { DropdownMenu },
    setup(props: any, { emit }: any) {
        const inHoverMode = ref(false);
        const getConstraintText = ref<any>();
        const getOutliersRuleText = ref<any>();

        const options = ref([
            {
                name: 'Edit',
                action: () => {
                    emit('edit', props.constraint);
                },
            },
            {
                name: 'Delete',
                requiresConfirm: true,
                action: () => {
                    emit('delete', props.constraint);
                },
            },
        ]);

        const typesChanged = (types: string[]) => {
            const cleaning = useCleaning(types);
            getConstraintText.value = cleaning.getConstraintText;
            getOutliersRuleText.value = cleaning.getOutliersRuleText;
        };

        watch(
            () => props.types,
            (types: string[]) => {
                typesChanged(types);
            },
        );

        typesChanged(props.types);

        return { inHoverMode, getConstraintText, getOutliersRuleText, options };
    },
};
