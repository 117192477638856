/* eslint-disable no-underscore-dangle */
import * as R from 'ramda';
import { FieldConfiguration } from '../views/mapping/mapping.types';
import { AssetStructure, StreamingStatus } from '../asset-config.types';
import { DataCheckinJob } from '../types';
import { Ref } from '@vue/composition-api';
import { ScheduleAPI } from '@/modules/workflow-designer/api';

const nameCapitalized = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

const dataTypes = ['integer', 'double', 'string', 'boolean', 'datetime', 'time', 'date', 'base64binary'];

export function useJobConfiguration(job: Ref<DataCheckinJob | null>) {
    const getPeriodicity = async (config: any) => {
        if (config.source === 'kafka') return 'Continous';
        if (config.source === 'api') {
            if (config.retrieval.type === 'periodic') {
                if (job.value?.workflowId) {
                    const { data: schedules } = await ScheduleAPI.getSchedules(job.value.workflowId);
                    const periods = {
                        hourly: 1,
                        daily: 2,
                        weekly: 3,
                        monthly: 4,
                    };

                    let period = 4;
                    schedules.forEach((schedule: any) => {
                        if (periods[schedule.frequency] < period) {
                            period = periods[schedule.frequency];
                        }
                    });

                    return nameCapitalized(Object.keys(periods).find((key) => periods[key] === period) as string);
                }
            }
            if (config.retrieval.type === 'polling') return 'Continous';
        }

        return 'Provider-dependent';
    };

    const getUnmappedStructure = async (harvester: any) => {
        return {
            kafkaTopic: harvester.params && harvester.params.topic ? harvester.params.topic : null,
            streamingStatus: harvester.source && harvester.source === 'kafka' ? StreamingStatus.Active : null,
            source: harvester.source,
            type: harvester.fileType === 'other' ? 'other' : 'json',
            periodicity: await getPeriodicity(harvester),
            schema: null,
            domain: null,
            primaryConcept: null,
            otherConcepts: [],
        };
    };

    const getStructure = async (
        harvester: any,
        mapping: any,
        conceptUIds: any,
        droppedFieldIds: number[],
    ): Promise<AssetStructure> => {
        const primaryConcept = mapping.concept;

        const structure: AssetStructure = {
            kafkaTopic: harvester.params && harvester.params.topic ? harvester.params.topic : null,
            streamingStatus: harvester.source && harvester.source === 'kafka' ? StreamingStatus.Active : null,
            dataType: harvester.dataType,
            source: harvester.source,
            type: harvester.fileType === 'other' ? 'other' : 'json',
            domain: {
                ...conceptUIds[mapping.domain.id],
                name: mapping.domain.name,
            },
            primaryConcept: {
                uid: conceptUIds[primaryConcept.id].uid,
                name: primaryConcept.name,
            },
            otherConcepts: [],
            temporalFields: [],
            spatialFields: [],
            periodicity: await getPeriodicity(harvester),
            schema: {
                [primaryConcept.name]: {
                    _uid: conceptUIds[primaryConcept.id].uid,
                    _type: primaryConcept.name,
                },
                customizedConcepts: mapping.customizedConcepts,
            },
        };

        mapping.fields.forEach((fieldConf: FieldConfiguration) => {
            const field = R.clone(fieldConf);
            if (!field.target.id || !field.target.title || droppedFieldIds.includes(field.target.id)) return;
            field.target.path = field.target.path.map((pathField: string) => pathField.replace('[]', ''));
            if (!R.hasPath(field.target.path, structure.schema)) {
                for (let i = 0; i < field.target.path.length; i++) {
                    const path = R.slice(0, i + 1, field.target.path);
                    if (!R.hasPath(path, structure.schema)) {
                        if (
                            !structure.otherConcepts.find(
                                (conc) => conc.uid === conceptUIds[field.target.parentIds[i]].uid,
                            )
                        ) {
                            structure.otherConcepts.push({
                                uid: conceptUIds[field.target.parentIds[i]].uid,
                                name: field.target.categories[i],
                            });
                        }
                        structure.schema = R.assocPath(
                            path,
                            {
                                _uid: conceptUIds[field.target.parentIds[i]].uid,
                                _type: field.target.categories[i],
                            },
                            structure.schema,
                        );
                    }
                }
            }

            if (field.metadata?.spatial && field.metadata?.index) {
                let fieldName = field.target.title;
                if (field.target.path && field.target.path.length > 0) {
                    fieldName = `${field.target.path.join('__')}__${field.target.title}`;
                }
                if (
                    !structure.spatialFields.find(
                        (spatialField) => field.target.id && spatialField.uid === conceptUIds[field.target.id].uid,
                    )
                ) {
                    structure.spatialFields.push({
                        uid: conceptUIds[field.target.id].uid,
                        name: fieldName,
                        type: field.target.type,
                    });
                }
            }

            if (field.metadata?.temporal && field.metadata?.index) {
                let fieldName = field.target.title;
                if (field.target.path && field.target.path.length > 0) {
                    fieldName = `${field.target.path.join('__')}__${field.target.title}`;
                }
                if (
                    !structure.temporalFields.find(
                        (temporalField) => field.target.id && temporalField.uid === conceptUIds[field.target.id].uid,
                    )
                ) {
                    structure.temporalFields.push({
                        uid: conceptUIds[field.target.id].uid,
                        name: fieldName,
                        type: field.target.type,
                    });
                }
            }

            structure.schema = R.assocPath(
                [...field.target.path, field.target.title] as string[],
                {
                    _uid: conceptUIds[field.target.id].uid,
                    _type: field.target.type,
                    _index: !!field.metadata?.index,
                },
                structure.schema,
            );
        });

        return structure;
    };

    const getLoaderSchema = (structure: AssetStructure): any => {
        if (!structure.primaryConcept) return {};
        const extractChildren = (fields: string[], values: Record<string, unknown>[]): Record<string, unknown>[] =>
            fields.reduce((result: Record<string, unknown>[], key: string) => {
                if (R.startsWith('_', key)) return result;

                const field = values[key];
                if (!dataTypes.includes(field._type)) {
                    const data = {
                        key,
                        type: 'object',
                        children: extractChildren(Object.keys(field), field),
                    };
                    result.push(data);
                    return result;
                }
                result.push({
                    uid: field._uid,
                    key,
                    type: field._type,
                    indexed: field._index,
                });
                return result;
            }, [] as Record<string, unknown>[]);

        const loaderConfig = {
            [structure.primaryConcept.name]: {
                children: extractChildren(
                    Object.keys((structure.schema as Record<string, any>)[structure.primaryConcept.name]),
                    (structure.schema as Record<string, any>)[structure.primaryConcept.name],
                ),
            },
        };

        return loaderConfig;
    };

    return { getPeriodicity, getStructure, getLoaderSchema, getUnmappedStructure };
}
