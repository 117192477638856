





























































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@vue-composable/axios';
import { StatusCode } from '@/modules/data-checkin/constants';
import { TwButton, WizardTabs } from '@/app/components';
import { useQuery, useResult, useErrors, useJsonObject } from '@/app/composable';
import GET_JOB from '../../graphql/getJob.graphql';
import AnonymisationConfiguration from './Configuration.vue';
import ReviewAndReport from './ReviewAndReport.vue';
import { useStep, useMapping } from '../../composable';
import { JobsAPI } from '../../api';
import StepCompletionModal from '../../components/StepCompletionModal.vue';

export default defineComponent({
    name: 'Anonymisation',
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    components: {
        AnonymisationConfiguration,
        ReviewAndReport,
        OrbitSpinner,
        WizardTabs,
        TwButton,
        StepCompletionModal,
    },
    setup(props, { root }) {
        const { loading, error, exec } = useAxios(true);
        const jobId = parseInt(`${props.id}`, 10);
        const steps = ref([{ title: 'Configuration' }, { title: 'Review and Report' }]);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const activeTab = ref(0);
        const nextStep = ref<any>(null);
        const hasChanges = ref<boolean>(false);
        const currentAnonymisationConfiguration: any = ref(null);
        const stats = ref<any>(null);
        const errorCode = ref<number | null>(null);
        const restarting = ref(false);
        const { extractMappingFieldNames } = useMapping([], null);
        const loadingFinalization = ref<boolean>(false);

        // Fetch job information
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { loading: jobLoading, error: jobError, result, onError, refetch } = useQuery(GET_JOB, { id: jobId });
        onError(checkGQLAuthentication);
        const job = useResult(result, null, (data: any) => data.job);

        const { getFixedJSON } = useJsonObject();

        const fixedSample = computed(() => {
            if (job.value && job.value.sample) {
                return getFixedJSON(job.value.sample);
            }
            return [];
        });

        // Fetch anonymisation configuration
        const anonymisation = ref<any>(null);
        const {
            isConfigEmpty,
            isFinalized,
            getNextStep,
            updateAssetAfterFailedStep,
            checkStepRestartEligibility,
            canRestart,
            setupUpdatedConfiguration,
        } = useStep(anonymisation, job);

        // Default (empty) configuration
        const configuration = ref<any>({
            kAnonymity: 2,
            lossLimit: 70,
            fields: [],
        });

        const isValid = computed<boolean>(
            () => configuration.value.fields.filter((obj: any) => obj.temp && obj.temp.invalid).length === 0,
        );

        const next = async () => {
            activeTab.value += 1;
        };

        const previous = () => {
            activeTab.value -= 1;
        };

        const save = async () => {
            try {
                await exec(
                    JobsAPI.updateStep(anonymisation.value.id, {
                        configuration: configuration.value,
                        serviceVersion: process.env.VUE_APP_ANONYMISER_VERSION,
                    }),
                );
                (root as any).$toastr.s('Anonymisation configuration saved successfuly', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving anonymisation configuration failed', 'Failed');
                hasChanges.value = true;
            }
        };

        const finalize = () => {
            if (isValid.value) {
                loadingFinalization.value = true;
                exec(
                    JobsAPI.updateStep(anonymisation.value.id, {
                        configuration: configuration.value,
                        serviceVersion: process.env.VUE_APP_ANONYMISER_VERSION,
                    }),
                ).then(() => {
                    getNextStep().then(async (stepTypeResponse: any) => {
                        nextStep.value = stepTypeResponse;

                        /**
                         * If loader step (order = 100) has a different status than "configuration",
                         * it means that the Asset has already been created
                         */
                        if (
                            anonymisation.value.status === StatusCode.Update &&
                            nextStep.value.order === 100 &&
                            nextStep.value.status !== StatusCode.Configuration
                        ) {
                            if (job.value.asset && job.value.asset.id) {
                                await updateAssetAfterFailedStep(job.value);
                                await exec(JobsAPI.finalize(anonymisation.value.id));
                                restartedStep.value = true;
                            } else {
                                (root as any).$toastr.e(
                                    'Failed finalizing revised Anonymisation step due to an error',
                                    'Failed',
                                );
                            }
                        } else {
                            await exec(JobsAPI.finalize(anonymisation.value.id));
                            showFinalizeModal.value = true;
                        }
                        loadingFinalization.value = false;
                    });
                });
            }
        };

        const stepStatus = computed(() =>
            anonymisation.value && anonymisation.value.status ? anonymisation.value.status : StatusCode.Configuration,
        );
        exec(JobsAPI.getStep(jobId, 'anonymiser')).then(async (resAnonymisation: any) => {
            anonymisation.value = resAnonymisation.data;
            if (anonymisation.value.message && anonymisation.value.message.errorCode) {
                errorCode.value = anonymisation.value.message.errorCode;
                stats.value = anonymisation.value.message.stats;
            } else {
                stats.value = anonymisation.value.stats;
            }

            // retrieve mapping configuration and extract the mapped fields
            exec(JobsAPI.getStep(jobId, 'mapping')).then(async (resMapping: any) => {
                if (!isConfigEmpty(resMapping.data.configuration)) {
                    if (isConfigEmpty(resAnonymisation.data.configuration)) {
                        const mappingConfig = resMapping.data.configuration;
                        const mappingFields = extractMappingFieldNames(mappingConfig.fields);
                        mappingFields.forEach((field: any) => {
                            const obj = R.clone(field);
                            obj.anonymisationType = 'insensitive';
                            obj.generalization = null;
                            obj.options = null;
                            configuration.value.fields.push(obj);
                        });
                    } else {
                        configuration.value = R.clone(resAnonymisation.data.configuration);

                        if (anonymisation.value.status === StatusCode.Update) {
                            /**
                             * check if any fields have been added/ removed/ modified after
                             * revised mapping and update the anonymisation configuration
                             */
                            configuration.value = await setupUpdatedConfiguration(
                                resMapping.data.configuration.fields,
                                configuration.value,
                            );
                            currentAnonymisationConfiguration.value = R.clone(configuration.value);
                        }
                    }
                }
                checkStepRestartEligibility();
                refetch(); // refetch job
            });
        });

        const changeKAnonymity = (k: string) => {
            configuration.value.kAnonymity = parseInt(k, 10);
        };

        const changeLossLimit = (limit: string) => {
            configuration.value.lossLimit = parseInt(limit, 10);
        };

        const restartStep = () => {
            restarting.value = true;
            try {
                exec(JobsAPI.restartStep(anonymisation.value.id)).then((res: any) => {
                    anonymisation.value = res.data;
                    currentAnonymisationConfiguration.value = anonymisation.value.configuration;

                    restarting.value = false;
                    (root as any).$toastr.s(
                        'The configuration of the anonymisation step is now available for updates.',
                        'Success',
                    );
                });
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the anonymisation step failed', 'Failed');
            }
        };

        const hasDifferenceInConfiguration = computed(() => {
            if (
                anonymisation.value &&
                anonymisation.value.status === StatusCode.Update &&
                anonymisation.value.message &&
                currentAnonymisationConfiguration.value
            ) {
                return JSON.stringify(currentAnonymisationConfiguration.value) !== JSON.stringify(configuration.value);
            }

            return true;
        });

        const pageLoading = computed(() => {
            return loadingFinalization.value || loading.value || jobLoading.value;
        });

        return {
            anonymisation,
            activeTab,
            jobLoading,
            jobError,
            job,
            jobId,
            steps,
            isFinalized,
            save,
            finalize,
            next,
            previous,
            loading,
            error,
            showFinalizeModal,
            nextStep,
            configuration,
            isValid,
            hasChanges,
            changeKAnonymity,
            changeLossLimit,
            stats,
            errorCode,
            fixedSample,
            canRestart,
            restartStep,
            restarting,
            hasDifferenceInConfiguration,
            restartedStep,
            pageLoading,
            stepStatus,
        };
    },
});
