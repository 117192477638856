



















































































































































import { defineComponent } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';

export default defineComponent({
    name: 'HandleNullValues',
    components: {
        ValidationProvider,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const changeHandleNullValuesMethod = (method: string) => {
            if (method === 'keep') {
                if (props.field.type === 'time') {
                    props.field.options.nullValues.replaceWith = { hours: '0', minutes: '0', seconds: '0' }; // eslint-disable-line no-param-reassign
                } else {
                    props.field.options.nullValues.replaceWith = null; // eslint-disable-line no-param-reassign
                }
            } else {
                if (props.field.type === 'string') {
                    props.field.options.nullValues.replaceWith = ''; // eslint-disable-line no-param-reassign
                }
            }
        };
        return { changeHandleNullValuesMethod };
    },
});
