


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, computed, ref, watch, reactive, watchEffect } from '@vue/composition-api';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, min_value, max_value } from 'vee-validate/dist/rules'; // eslint-disable-line
import VueTagsInput from '@johmun/vue-tags-input';
import { useAxios } from '@vue-composable/axios';
import ClickOutside from 'vue-click-outside';
import { parseString, processors } from 'xml2js';

import { AppAPI } from '@/modules/data-checkin/api';
import {
    ButtonGroup,
    ConfirmModal,
    FormBlock,
    TwButton,
    JsonParser,
    InputErrorIcon,
    AlertBanner,
    Scrollbar,
    JsonEditor,
} from '@/app/components';
import { useFilters, useJsonObject } from '@/app/composable';
import { ParameterModal, AdditionalResponseData } from '../../components';
import { formatDate, getDynamicDate } from './external-api/tranformations';
import Schedules from './external-api/Schedules.vue';
import { useHarvester } from '../../composable';

extend('required', {
    ...required,
    message: (field) => {
        const { splitLowerCase } = useFilters();
        const fieldName = splitLowerCase(field);
        return `The ${fieldName} is required`;
    },
});

extend('min_value', min_value);
extend('max_value', max_value);

export interface Param {
    key: {
        name: string;
        type: string;
    };
    value: {
        type: string | null;
        format?: string | null;
        value?: string | number | null;
    };
    sensitive: boolean;
    type?: string | null;
    format?: string | null;
    title?: string | null;
    description?: string | null;
    optional?: boolean;
}

export interface Header {
    key: string;
    value: string;
    sensitive: boolean;
    tags?: Array<any> | null;
}

export default defineComponent({
    name: 'ApiConfiguration',
    model: {
        prop: 'configuration',
    },
    directives: {
        ClickOutside,
    },
    components: {
        ButtonGroup,
        ConfirmModal,
        FormBlock,
        InputErrorIcon,
        JsonParser,
        TwButton,
        ValidationObserver,
        ValidationProvider,
        JsonEditor,
        VueTagsInput,
        ParameterModal,
        AlertBanner,
        Schedules,
        AdditionalResponseData,
        Scrollbar,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        jobConfig: {
            type: Object,
            required: false,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        basePath: {
            type: String,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        workflowId: {
            type: String,
            required: true,
        },
        running: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { root, emit }) {
        const enableElements = ref(false);
        const separator = '||';
        const loading = ref<boolean>(false);
        const urlRef = ref<any>(null);
        const editingURL = ref<boolean>(true);
        const baseURL = ref<string>('');
        const apiValidationRef = ref<any>(false);
        const showChangeAuthenticationModal = ref(false);
        const showChangeMethodModal = ref(false);
        const showChangePaginationModal = ref(false);
        const showConfirmEditLoginModal = ref(false);
        const showConfirmIgnoreCertificatesModal = ref(false);
        const alertType = ref<string | null>(null);
        const showParameterModal = ref(false);
        const newAuthenticationMethod = ref<string | null>(null);
        const newMethod = ref<string | null>(null);
        const oldMethod = ref<string | null>(null);
        const newPaginationType = ref<string | null>(null);
        const loginResponse = ref<any>(null);
        const loginResponseKeys = ref<string[]>([]);
        const invalidLoginBody = ref<boolean>(false);
        const invalidQueryBody = ref<boolean>(false);
        const paginationParameters = ref<Param[]>([]);
        const paramChanged = ref<boolean>(false);
        const editParam = reactive<any>({
            param: null,
            type: null,
            index: null,
        });
        const { exec } = useAxios(true);
        const finalSample = ref<any>(props.sample);
        const tomorrow = computed(() => {
            const date = new Date();
            return date.setDate(date.getDate() + 1);
        });

        const { changeFinalSample, limitResponse, findArrayPath, checkEmptyJSON } = useHarvester(root, emit);

        const isSampleArray = computed(() => R.is(Array, props.configuration.response.data));
        const hasResponseData = computed(() => !checkEmptyJSON(props.configuration.response.data));

        const previousParams = ref(R.clone(props.configuration.params));
        const previousPagination = ref(R.clone(props.configuration.pagination));
        const hasUrlChanges = ref(false);

        const schedules: any = ref([]);
        const retrievalTypes = {
            immediately: 'Retrieve Immediately',
            once: 'Retrieve Once',
            periodic: 'Periodic Retrieval (according to schedule)',
            // polling: 'Polling (every 60 seconds)',
        };

        watchEffect(() => {
            for (let f = 0; f < Object.keys(props.configuration.params).length; f++) {
                const field = Object.keys(props.configuration.params)[f];
                if (JSON.stringify(props.configuration.params[field]) !== JSON.stringify(previousParams.value[field])) {
                    hasUrlChanges.value = true;
                    previousParams.value[field] = R.clone(props.configuration.params[field]);
                }
            }

            for (let f = 0; f < Object.keys(props.configuration.pagination).length; f++) {
                const field = Object.keys(props.configuration.pagination)[f];
                if (
                    JSON.stringify(props.configuration.pagination[field]) !==
                    JSON.stringify(previousPagination.value[field])
                ) {
                    hasUrlChanges.value = true;
                    previousPagination.value[field] = R.clone(props.configuration.pagination[field]);
                }
            }
        });

        const focusChoice = ref<string>(props.jobConfig && props.jobConfig.multiple ? 'specificPath' : 'wholeObject');

        const focusSelectedNestedArrayBasePath = ref<string | null>(null);

        const { getSuperObject, convertToArray } = useJsonObject();

        /**
         * Calculates if there are any inner arrays, in order to give the user the option to select a different base path other than the root.
         */
        const focusNestedArrays = computed(() => {
            let arrays: string[] = [];
            if (props.configuration.response.data && !isSampleArray.value) {
                Object.keys(props.configuration.response.data).forEach((key: string) => {
                    const objArrays: string[] | null = findArrayPath(props.configuration.response.data[key], key);
                    if (objArrays) {
                        arrays = [...arrays, ...objArrays];
                    }
                });
            }
            return arrays;
        });

        /**
         * Calculates to true in case the user has selected a different base path other than the root but has also selected nodes outside
         * this base path so that we show to the user a warning how these node selections will be handled
         */
        const focusSelectedObjectsFromOutside = computed(() => {
            if (
                props.jobConfig?.basePath &&
                focusChoice.value === 'specificPath' &&
                focusSelectedNestedArrayBasePath.value
            ) {
                for (let i = 0; i < props.configuration.response.selectedItems.length; i++) {
                    const arrayPath = props.configuration.response.selectedItems[i];
                    if (!arrayPath.startsWith(props.jobConfig.basePath)) {
                        return true;
                    }
                }
            }
            return false;
        });

        const additionalPaths = computed(() => {
            const paths = [];
            for (
                let a = 0;
                props.configuration.response.additional && a < props.configuration.response.additional.length;
                a++
            ) {
                const additionalData = props.configuration.response.additional[a];
                if (isSampleArray.value) {
                    paths.push(`${props.basePath}[0]${separator}${additionalData.key}`);
                } else {
                    paths.push(`${props.basePath}${separator}${additionalData.key}`);
                }
            }

            return paths;
        });

        const additionalDataKeyValue = computed(() => {
            const result = {};
            for (
                let a = 0;
                props.configuration.response.additional && a < props.configuration.response.additional.length;
                a++
            ) {
                const additionalData = props.configuration.response.additional[a];
                result[additionalData.key] = additionalData.displayValue;
            }

            return result;
        });

        watch(
            () => focusChoice.value,
            (choice: string) => {
                if (
                    choice === 'specificPath' &&
                    focusNestedArrays.value.length > 0 &&
                    !focusSelectedNestedArrayBasePath.value
                ) {
                    // eslint-disable-next-line prefer-destructuring
                    focusSelectedNestedArrayBasePath.value = focusNestedArrays.value[0];
                }
            },
        );

        if (
            !isSampleArray.value &&
            props.jobConfig &&
            props.jobConfig.basePath &&
            focusNestedArrays.value.includes(props.jobConfig.basePath.replace(`${props.basePath}${separator}`, ''))
        ) {
            focusSelectedNestedArrayBasePath.value = props.jobConfig.basePath.replace(
                `${props.basePath}}${separator}`,
                '',
            );
        } else if (focusNestedArrays.value.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            focusSelectedNestedArrayBasePath.value = focusNestedArrays.value[0];
        }

        const jobConfig = computed(() => {
            if (props.configuration.response.data && props.basePath) {
                let selectedItems = [];
                let { basePath }: { basePath: string | null } = props;
                let multiple = false;

                if (props.configuration?.response?.selectedItems) {
                    selectedItems = props.configuration.response.selectedItems;
                }

                if (isSampleArray.value) {
                    multiple = true;
                } else if (focusChoice.value === 'specificPath') {
                    basePath = `${props.basePath}${separator}${focusSelectedNestedArrayBasePath.value}`;
                    multiple = true;
                }

                return {
                    basePath,
                    multiple,
                    selectedItems,
                };
            }

            return null;
        });

        watch(
            () => jobConfig.value,
            (config: any) => {
                if (!props.completed) {
                    emit('job-config-change', config);
                }
            },
        );

        const errorAlert: any = reactive({
            title: null,
            body: null,
            showIgnore: false,
        });

        const autocompleteTags = computed(() => {
            const autoTags = [];
            for (let i = 0; i < loginResponseKeys.value.length; i += 1) {
                autoTags.push({ text: loginResponseKeys.value[i] });
            }
            return autoTags;
        });

        const loginAlert: any = reactive({
            isError: true,
            title: null,
            body: null,
            showIgnore: false,
        });

        const isConfigurationSaved = ref<boolean>(false);
        const isAuthenticationConfigurationSaved = ref<boolean>(false);
        watch(
            () => props.configuration.isSaved,
            (isSaved) => {
                if (isSaved) {
                    isConfigurationSaved.value = true;
                    baseURL.value = props.configuration.params.url;
                    editingURL.value = false;
                }
                isAuthenticationConfigurationSaved.value = isConfigurationSaved.value;
            },
        );

        if (props.configuration.isSaved) {
            isConfigurationSaved.value = true;
            isAuthenticationConfigurationSaved.value = isConfigurationSaved.value;
            baseURL.value = props.configuration.params.url;
            editingURL.value = false;
        }

        const inVault = (str: string): boolean => {
            if (str) {
                return str.startsWith('vault://');
            }
            return false;
        };

        const addHeaderTag = (index: number, param: string, newTags: any) => {
            props.configuration.params.headerTags[index].tags = []; // eslint-disable-line no-param-reassign
            let newValue = '';
            for (let i = 0; i < newTags.length; i += 1) {
                const newtag = newTags[i];
                if (!loginResponseKeys.value.includes(newtag.text)) {
                    newtag.classes = 'userParam';
                    newValue += `${newtag.text}`;
                } else {
                    newtag.classes = 'loginParam';
                    newValue += `{${newtag.text}}`;
                }
                props.configuration.params.headerTags[index].tags.push(newtag);
            }
            props.configuration.params.headers[index].value = newValue; // eslint-disable-line no-param-reassign
        };

        // Reset payload if API method is GET
        watch(
            () => props.configuration.params.method,
            (method: string) => {
                if (method === 'GET') {
                    props.configuration.params.payload = null; // eslint-disable-line no-param-reassign
                    invalidQueryBody.value = false;
                }
            },
        );

        const extractURLParams = (url: string) => {
            if (!url || url.length === 0) return;
            const matches = url.match(/\{([A-Za-z0-9_-]+)\}/g);
            const parameters = [];
            if (matches !== null) {
                for (let i = 0; i < matches.length; i += 1) {
                    const key = matches[i].split('{')[1].split('}')[0];
                    const record = R.find(R.propEq('key', key))(props.configuration.params.urlParams) as any;
                    const param = {
                        key,
                        value: record?.value ? record?.value : null,
                        sensitive: record?.sensitive ? record?.sensitive : false,
                        type: record?.type ? record?.type : null,
                        format: record?.format ? record?.format : null,
                    };
                    parameters.push(param);
                }
            }
            props.configuration.params.urlParams.length = 0; // eslint-disable-line no-param-reassign
            parameters.forEach((param) => props.configuration.params.urlParams.push(param));
        };

        extractURLParams(props.configuration.params.url);

        const parseURL = () => {
            if (!editingURL.value || showChangeMethodModal.value) {
                showChangeMethodModal.value = false;
                return;
            }
            let valid = true;
            const urlParts = baseURL.value.split('?');
            if (urlParts.length > 2) {
                valid = false;
            }
            if (valid && urlParts.length < 3 && urlParts[1]) {
                const queryParams = urlParts[1].split('&');
                queryParams.forEach((query: string) => {
                    const queryParts = query.split('=');
                    if (
                        queryParts[0].length > 0 &&
                        queryParts[0].charAt(0) === '{' &&
                        queryParts[0].charAt(queryParts[0].length - 1) === '}'
                    ) {
                        queryParts[0] = queryParts[0].slice(1, -1);
                    }
                    if (queryParts[0].length === 0 || !queryParts[0].match(/[A-Za-z0-9_-]+$/g)) {
                        valid = false;
                        return;
                    }
                    let queryValue = '';
                    if (queryParts[1]) [queryValue] = [queryParts[1]];
                    props.configuration.params.parameters.push({
                        key: {
                            name: queryParts[0],
                            type: 'query',
                        },
                        value: {
                            value: queryValue,
                            type: 'static',
                            format: null,
                        },
                        sensitive: false,
                    });
                });
            }
            if (urlRef.value && urlRef.value.errors.length === 0) {
                if (valid) {
                    extractURLParams(urlParts[0]);
                    [baseURL.value] = [urlParts[0]];
                    [props.configuration.params.url] = [urlParts[0]]; // eslint-disable-line no-param-reassign
                    if (props.configuration.params.url.length > 0) editingURL.value = false;
                } else {
                    urlRef.value.errors.push('Invalid url');
                }
            }
        };

        const cancelEditingURL = () => {
            baseURL.value = props.configuration.params.url;
            editingURL.value = false;
        };

        const capitalise = (str: string) => {
            return str.charAt(0).toUpperCase() + str.slice(1);
        };

        const extractBodyParams = () => {
            if (!props.configuration.params.payload) return;
            const matches = props.configuration.params.payload.match(/\{([A-Za-z0-9_-]+)\}/g);
            const parameters = props.configuration.params.parameters.filter(
                (param: Param) => param.key.type !== 'body',
            );
            const bodyParams = props.configuration.params.parameters.filter(
                (param: Param) => param.key.type === 'body',
            );
            if (matches !== null) {
                for (let i = 0; i < matches.length; i += 1) {
                    const key = matches[i].split('{')[1].split('}')[0];
                    const record = bodyParams.find((param: Param) => param.key.name === key);
                    const param: Param = {
                        key: {
                            name: key,
                            type: record?.key.type ? record?.key.type : 'body',
                        },
                        value: {
                            value: record?.value.value ? record?.value.value : null,
                            type: record?.value.type ? record?.value.type : null,
                            format: record?.value.format ? record?.value.format : null,
                        },
                        sensitive: record?.sensitive ? record?.sensitive : false,
                    };
                    parameters.unshift(param);
                }
            }
            props.configuration.params.parameters = parameters; // eslint-disable-line no-param-reassign
        };

        extractBodyParams();

        const valueClasses = (type: string) => {
            switch (type) {
                case 'dynamic':
                    return 'p-1 bg-green-200 rounded-lg';
                case 'authentication':
                    return 'p-1 bg-purple-200 rounded-lg';
                case 'pagination':
                    return 'p-1 bg-orange-200 rounded-lg';
                default:
                    return '';
            }
        };

        const addHeader = () => {
            props.configuration.params.headers.push({
                key: '',
                value: '',
                sensitive: false,
            });
            props.configuration.params.headerTags.push({
                value: '',
                tags: [],
            });
        };

        const configureParam = (param: Param, type: string, index: number) => {
            editParam.param = R.clone(param);
            editParam.type = type;
            editParam.index = index;
            showParameterModal.value = true;
        };

        const addParam = (param: Param) => {
            props.configuration.params.parameters.push(param);
            showParameterModal.value = false;
        };

        const updateParam = (param: any) => {
            if (editParam.type === 'url') {
                props.configuration.params.urlParams[editParam.index] = param; // eslint-disable-line no-param-reassign
            } else if (editParam.type === 'pagination') {
                props.configuration.pagination.parameters[editParam.index].value.value = param.value.value; // eslint-disable-line no-param-reassign
            } else {
                props.configuration.params.parameters[editParam.index] = param; // eslint-disable-line no-param-reassign
            }
            paramChanged.value = true;
            showParameterModal.value = false;
        };

        const removeHeader = (index: number) => {
            props.configuration.params.headers.splice(index, 1);
            props.configuration.params.headerTags.splice(index, 1);
        };

        const removeParameter = (index: number) => {
            props.configuration.params.parameters.splice(index, 1);
        };

        const changeIgnoreStatus = (event: any, param: Param) => {
            if (event.target.checked) {
                const idx = props.configuration.pagination.parameters.indexOf(param);
                props.configuration.pagination.parameters.splice(idx, 1);
            } else {
                props.configuration.pagination.parameters.unshift(param);
            }
        };

        const urlPreview = computed(() => {
            let finalURL = baseURL.value;
            if (paramChanged.value) paramChanged.value = false;
            for (let i = 0; i < props.configuration.params.urlParams.length; i += 1) {
                let value = '';
                if (props.configuration.params.urlParams[i].value) {
                    const classes = valueClasses(props.configuration.params.urlParams[i].type);
                    value = `<span class="${classes}">${props.configuration.params.urlParams[i].value}</span>`;
                }
                finalURL = finalURL.replace(`{${props.configuration.params.urlParams[i].key}}`, value);
            }
            const parameters = props.configuration.params.parameters.concat(props.configuration.pagination.parameters);
            const queryParams = parameters.filter((param: Param) => param.key.type === 'query');
            if (queryParams.length > 0) {
                finalURL += '?';
            }
            queryParams.forEach((param: any) => {
                finalURL += `${param.key.name}=`;
                const classes = valueClasses(param.value.type);
                finalURL += `<span class="${classes}">${param.value.value}</span>&`;
            });
            if (queryParams.length > 0) {
                finalURL = finalURL.slice(0, -1);
            }
            if (!finalURL.startsWith('http://') && !finalURL.startsWith('https://')) {
                finalURL = `http://${finalURL}`; // eslint-disable-line no-param-reassign
            }
            return finalURL;
        });

        const isDuplicateParam = (type: string, key: string) => {
            let keyNames: string[] = [];
            switch (type) {
                case 'url':
                    keyNames = props.configuration.params.urlParams.map((param: any) => param.key);
                    break;
                case 'body': {
                    const bodyParams1 = props.configuration.params.parameters.filter(
                        (param: Param) => param.key.type === 'body',
                    );
                    const bodyParams2 = props.configuration.pagination.parameters.filter(
                        (param: Param) => param.key.type === 'body',
                    );
                    const allBodyParams = bodyParams1.concat(bodyParams2);
                    keyNames = allBodyParams.map((param: Param) => param.key.name);
                    break;
                }
                default: {
                    const queryParams1 = props.configuration.params.parameters.filter(
                        (param: Param) => param.key.type === 'query',
                    );
                    const queryParams2 = props.configuration.pagination.parameters.filter(
                        (param: Param) => param.key.type === 'query',
                    );
                    const allQueryParams = queryParams1.concat(queryParams2);
                    keyNames = allQueryParams.map((param: Param) => param.key.name);
                }
            }
            const duplicates = keyNames.filter((param: any, index: number) => keyNames.indexOf(param) !== index);
            return duplicates.includes(key);
        };

        const hasDuplicateParams = () => {
            const urlKeyNames = props.configuration.params.urlParams.map((param: any) => param.key);
            const urlDuplicates = urlKeyNames.filter(
                (param: any, index: number) => urlKeyNames.indexOf(param) !== index,
            );
            const queryParams1 = props.configuration.params.parameters.filter(
                (param: Param) => param.key.type === 'query',
            );
            const queryParams2 = props.configuration.pagination.parameters.filter(
                (param: Param) => param.key.type === 'query',
            );
            const allQueryParams = queryParams1.concat(queryParams2);
            const queryKeyNames = allQueryParams.map((param: any) => param.key.name);
            const queryDuplicates = queryKeyNames.filter(
                (param: any, index: number) => queryKeyNames.indexOf(param) !== index,
            );
            const bodyParams1 = props.configuration.params.parameters.filter(
                (param: Param) => param.key.type === 'body',
            );
            const bodyParams2 = props.configuration.pagination.parameters.filter(
                (param: Param) => param.key.type === 'body',
            );
            const allBodyParams = bodyParams1.concat(bodyParams2);
            const bodyKeyNames = allBodyParams.map((param: any) => param.key.name);
            const bodyDuplicates = bodyKeyNames.filter(
                (param: any, index: number) => bodyKeyNames.indexOf(param) !== index,
            );
            if (urlDuplicates.length > 0 || queryDuplicates.length > 0 || bodyDuplicates.length > 0) {
                (root as any).$toastr.e('Duplicate parameters', 'Error');
                return true;
            }
            return false;
        };

        const hasMissingValues = () => {
            for (let i = 0; i < props.configuration.params.urlParams.length; i += 1) {
                if (!props.configuration.params.urlParams[i].value) {
                    (root as any).$toastr.e('Missing URL parameter value(s)', 'Error');
                    return true;
                }
            }
            for (let i = 0; i < props.configuration.params.parameters.length; i += 1) {
                if (!props.configuration.params.parameters[i].value.value) {
                    (root as any).$toastr.e('Missing parameter value(s)', 'Error');
                    return true;
                }
            }
            for (let i = 0; i < props.configuration.params.headers.length; i += 1) {
                if (!props.configuration.params.headers[i].value) {
                    (root as any).$toastr.e('Missing header value(s)', 'Error');
                    return true;
                }
            }
            return false;
        };

        const isLoginTested = () => {
            if (props.configuration.auth.method === 'custom' && !props.configuration.auth.loginTested) {
                (root as any).$toastr.e('Login is not tested', 'Error');
                return false;
            }
            return true;
        };

        const testLogin = async () => {
            loading.value = true;
            invalidLoginBody.value = false;
            if (
                !props.configuration.auth.url.startsWith('http://') &&
                !props.configuration.auth.url.startsWith('https://')
            ) {
                props.configuration.auth.url = `http://${props.configuration.auth.url}`; // eslint-disable-line no-param-reassign
            }
            if (props.configuration.auth.payload !== null && props.configuration.auth.payload.length === 0) {
                props.configuration.auth.payload = null; // eslint-disable-line no-param-reassign
            }
            let payload = null;
            try {
                payload = JSON.parse(props.configuration.auth.payload);
            } catch (e) {
                invalidLoginBody.value = true;
                loading.value = false;
                return;
            }

            const config: any = {
                method: 'POST',
                baseURL: props.configuration.auth.url,
                data: payload,
                ignoreCertificates: props.configuration.auth.ignoreCertificates,
            };

            exec(AppAPI.testAPI(config))
                .then((response: any) => {
                    loading.value = false;
                    props.configuration.auth.loginTested = true; // eslint-disable-line no-param-reassign
                    loginResponseKeys.value = Object.keys(response.data); // eslint-disable-line no-param-reassign
                    loginResponse.value = response.data;
                    loginAlert.title = 'Successful Login';
                    loginAlert.body = '';
                    loginAlert.isError = false;
                    loginAlert.showIgnore = false;
                })
                .catch((error) => {
                    if (error.response) {
                        props.configuration.auth.loginTested = false; // eslint-disable-line no-param-reassign
                        loginAlert.title = `Failed: ${error.response.status} ${error.response.statusText}`;
                        loginAlert.body = error.response.data ? error.response.data : null;
                        loginAlert.isError = true;
                    } else if (error.request) {
                        // Axios exception
                        props.configuration.auth.loginTested = false; // eslint-disable-line no-param-reassign
                        loginAlert.title = 'Login Failed: Connection Error';
                        loginAlert.body = null;
                        loginAlert.isError = true;
                    } else {
                        // Other error
                        props.configuration.auth.loginTested = false; // eslint-disable-line no-param-reassign
                        loginAlert.title = 'Login Failed: Connection Error';
                        loginAlert.body = error.message;
                        loginAlert.isError = true;
                    }
                    if (
                        loginAlert.body &&
                        loginAlert.body.message &&
                        loginAlert.body.message === 'SSL Error: self signed certificate in certificate chain'
                    ) {
                        loginAlert.showIgnore = true;
                    } else {
                        loginAlert.showIgnore = false;
                    }
                    loading.value = false;
                });
        };

        const extractApiInformation = (): { path: string; headers: any; payload: any } => {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset();
            const utc = new Date(now.getTime() + timezoneOffset * 60 * 1000);
            let path = props.configuration.params.url;
            for (let i = 0; i < props.configuration.params.urlParams.length; i += 1) {
                const value =
                    props.configuration.params.urlParams[i].value.indexOf('{') !== -1
                        ? loginResponse.value[
                              props.configuration.params.urlParams[i].value.substring(
                                  1,
                                  props.configuration.params.urlParams[i].value.length - 1,
                              )
                          ]
                        : props.configuration.params.urlParams[i].value;
                path = path.replace(`{${props.configuration.params.urlParams[i].key}}`, value);
            }

            const headers: any = {};
            for (let i = 0; i < props.configuration.params.headers.length; i += 1) {
                if (props.configuration.params.headers[i].key) {
                    // eslint-disable-next-line no-param-reassign
                    props.configuration.params.headers[i].value = props.configuration.params.headers[i].value.trim();
                    let newValue = props.configuration.params.headers[i].value;
                    if (loginResponse.value) {
                        const keys = Object.keys(loginResponse.value);
                        for (let j = 0; j < keys.length; j += 1) {
                            newValue = newValue.replace(`{${keys[j]}}`, loginResponse.value[keys[j]]);
                        }
                    }
                    headers[props.configuration.params.headers[i].key] = newValue;
                }
            }
            for (let i = 0; i < props.configuration.auth.headers.length; i += 1) {
                headers[props.configuration.auth.headers[i].key] = props.configuration.auth.headers[i].value;
            }
            let { payload } = props.configuration.params;
            const parameters = props.configuration.params.parameters.concat(props.configuration.pagination.parameters);
            let queryPath = '?';
            parameters.forEach((param: Param) => {
                if (param.key.type === 'query') {
                    if (param.value.value !== undefined && param.value.value !== null) {
                        const parts = param.value.value.toString().split(':');
                        const format = param.value.format ? param.value.format : null;
                        const dynamicOption = parts[0];
                        const dynamicValue = parts[1] ? parseInt(parts[1], 10) : -1;
                        let { value } = param.value;
                        if (param.value.type === 'dynamic')
                            value = formatDate(format, new Date(getDynamicDate(dynamicOption, utc, dynamicValue)));
                        else if (param.value.type === 'authentication') value = loginResponse.value[value];
                        queryPath += `${param.key.name}=${value}&`;
                    }
                } else if (props.configuration.params.method !== 'GET' && param.key.type === 'body') {
                    if (param.value.value !== undefined && param.value.value !== null) {
                        const parts = param.value.value.toString().split(':');
                        const format = param.value.format ? param.value.format : null;
                        const dynamicOption = parts[0];
                        const dynamicValue = parts[1] ? parseInt(parts[1], 10) : -1;
                        let { value } = param.value;
                        if (param.value.type === 'dynamic')
                            value = `"${formatDate(
                                format,
                                new Date(getDynamicDate(dynamicOption, utc, dynamicValue)),
                            )}"`;
                        else if (param.value.type === 'authentication') {
                            value =
                                R.type(loginResponse.value[value]) === 'String'
                                    ? `"${loginResponse.value[value]}"`
                                    : loginResponse.value[value];
                        } else if (param.value.type === 'string') value = `"${value}"`;
                        payload = payload.replace(`{${param.key.name}}`, value);
                    }
                }
            });
            if (queryPath.length > 1) {
                queryPath = queryPath.slice(0, -1);
                path += queryPath;
            }
            path = path.split('+').join('%2B');

            if (props.configuration.params.payload !== null && props.configuration.params.payload.length === 0) {
                props.configuration.params.payload = null; // eslint-disable-line no-param-reassign
            }
            try {
                payload = JSON.parse(payload);
                if (R.type(payload) === 'Array') {
                    invalidQueryBody.value = true;
                    loading.value = false;
                    throw Error('Invalid payload');
                }
                props.configuration.pagination.parameters.forEach((param: Param) => {
                    if (param.key.type === 'body') {
                        payload[param.key.name] = param.value.value;
                    }
                });
            } catch (e) {
                invalidQueryBody.value = true;
                loading.value = false;
                throw Error('Invalid payload');
            }

            return { path, headers, payload };
        };

        const addAt = (attrName: string) => {
            return `@${attrName}`;
        };

        const executeApi = (): Promise<void> => {
            loading.value = true;
            return new Promise((resolve, reject) => {
                const { path, headers, payload } = extractApiInformation();
                const config: any = {
                    method: props.configuration.params.method,
                    baseURL: path,
                    headers,
                    ignoreCertificates: props.configuration.params.ignoreCertificates,
                };
                config.headers.Accept = '*/*';
                config.data = payload ? JSON.stringify(payload) : null;

                exec(AppAPI.testAPI(config))
                    .then((response: any) => {
                        loading.value = false;
                        errorAlert.title = null;
                        errorAlert.body = null;
                        errorAlert.showIgnore = false;
                        let { data } = response;
                        if (props.configuration.fileType === 'xml') {
                            parseString(
                                data,
                                {
                                    attrkey: '@',
                                    charkey: '$',
                                    explicitCharkey: false,
                                    trim: true,
                                    emptyTag: {},
                                    explicitArray: false,
                                    mergeAttrs: true,
                                    attrNameProcessors: [addAt],
                                    attrValueProcessors: [processors.parseNumbers, processors.parseBooleans],
                                    valueProcessors: [processors.parseNumbers, processors.parseBooleans],
                                },
                                (err, result) => {
                                    if (!err) {
                                        data = result;
                                    } else {
                                        (root as any).$toastr.e('Invalid API response format', 'Error');
                                        errorAlert.title = 'Failed: Invalid API response format';
                                        errorAlert.body = 'The response is not a valid XML format';
                                        reject();
                                    }
                                },
                            );
                        } else if (props.configuration.fileType === 'json') {
                            try {
                                if (typeof data !== 'string') {
                                    data = JSON.stringify(data);
                                }
                                data = JSON.parse(data);
                            } catch (e) {
                                (root as any).$toastr.e('Invalid API response format', 'Error');
                                errorAlert.title = 'Failed: Invalid API response format';
                                errorAlert.body = 'The response is not a valid JSON format';
                                reject();
                            }
                        }
                        const superObj = getSuperObject(R.clone(data), {});
                        const fixedData = convertToArray(R.clone(data), superObj);
                        const updatedData = limitResponse(fixedData, 10);

                        // only clear selected items if the response is different
                        if (
                            props.configuration.response.selectedItems &&
                            JSON.stringify(updatedData) !== JSON.stringify(props.configuration.response.data)
                        ) {
                            props.configuration.response.selectedItems.splice(0);
                        }
                        props.configuration.response.data = updatedData; // eslint-disable-line no-param-reassign

                        resolve();
                    })
                    .catch((error) => {
                        (root as any).$toastr.e('API connection failed', 'Error');
                        if (error.response) {
                            errorAlert.title = `Failed: ${error.response.status} ${error.response.statusText}`;
                            errorAlert.body = error.response.data ? error.response.data : null;
                        } else if (error.request) {
                            // Axios exception
                            errorAlert.title = 'Failed: API Connection Error';
                            errorAlert.body = null;
                        } else {
                            // Other error
                            errorAlert.title = 'Failed: API Connection Error';
                            errorAlert.body = error.message;
                        }
                        if (
                            errorAlert.body &&
                            errorAlert.body.message &&
                            errorAlert.body.message === 'SSL Error: self signed certificate in certificate chain'
                        ) {
                            errorAlert.showIgnore = true;
                        } else {
                            errorAlert.showIgnore = false;
                        }
                        loading.value = false;
                        reject();
                    });
            });
        };

        const testAPIConnection = async () => {
            if (editingURL.value) parseURL();

            invalidQueryBody.value = false;
            if (apiValidationRef.value) {
                const valid = await apiValidationRef.value.validate();
                if (!valid || hasDuplicateParams() || hasMissingValues() || !isLoginTested()) {
                    loading.value = false;
                    return;
                }
            }
            if (props.isFinalized) {
                loading.value = false;
                emit('next-tab');
                return;
            }

            if (hasUrlChanges.value) {
                props.configuration.response.selectedItems.splice(0);
            }
            if (
                !props.configuration.params.url.startsWith('http://') &&
                !props.configuration.params.url.startsWith('https://')
            ) {
                props.configuration.params.url = `http://${props.configuration.params.url}`; // eslint-disable-line no-param-reassign
            }

            executeApi()
                .then(() => {
                    emit('next-tab');
                    hasUrlChanges.value = false;
                })
                .catch(() => {
                    emit('scroll-up');
                });
        };

        const modifyFinalSample = (sample: any) => {
            finalSample.value = sample;
            changeFinalSample(sample, props.configuration.source);
        };

        const validate = async () => {
            return apiValidationRef.value.validate();
        };

        const confirmEditLogin = () => {
            showConfirmEditLoginModal.value = true;
        };

        const editLogin = () => {
            props.configuration.auth.loginTested = false; // eslint-disable-line no-param-reassign
            loginAlert.title = null;
            loginAlert.body = null;
            loginAlert.showIgnore = false;
            for (let i = 0; i < props.configuration.params.urlParams.length; i += 1) {
                if (
                    props.configuration.params.urlParams[i].value &&
                    props.configuration.params.urlParams[i].value.indexOf('{') !== -1
                ) {
                    props.configuration.params.urlParams[i].type = null; // eslint-disable-line no-param-reassign
                    props.configuration.params.urlParams[i].value = ''; // eslint-disable-line no-param-reassign
                }
            }
            for (let i = 0; i < props.configuration.params.headers.length; i += 1) {
                if (
                    props.configuration.params.headers[i].value &&
                    props.configuration.params.headers[i].value.indexOf('{') !== -1
                ) {
                    props.configuration.params.headers[i].value = ''; // eslint-disable-line no-param-reassign
                    props.configuration.params.headerTags[i].value = ''; // eslint-disable-line no-param-reassign
                    props.configuration.params.headerTags[i].tags.splice(0); // eslint-disable-line no-param-reassign
                }
            }
            props.configuration.params.parameters.forEach((param: Param) => {
                if (param.value.type === 'authentication') {
                    param.value.type = null; // eslint-disable-line no-param-reassign
                    param.value.value = ''; // eslint-disable-line no-param-reassign
                }
            });
            loginResponseKeys.value.splice(0);
            showConfirmEditLoginModal.value = false;
        };

        const changeAuthenticationMethod = (method: string) => {
            loginAlert.title = null;
            loginAlert.body = null;
            loginAlert.showIgnore = false;
            invalidLoginBody.value = false;
            props.configuration.auth.accessToken = null; // eslint-disable-line no-param-reassign
            props.configuration.auth.url = null; // eslint-disable-line no-param-reassign
            props.configuration.auth.payload = null; // eslint-disable-line no-param-reassign
            props.configuration.auth.headers.splice(0);
            props.configuration.auth.method = method; // eslint-disable-line no-param-reassign
            showChangeAuthenticationModal.value = false;
        };

        const confirmChangeAuthentication = (method: string) => {
            if (method === props.configuration.auth.method) return;

            newAuthenticationMethod.value = method;
            showChangeAuthenticationModal.value = true;
        };

        const setAuthenticationHeader = () => {
            if (props.configuration.auth.accessToken === null || props.configuration.auth.accessToken.length === 0) {
                for (let i = 0; i < props.configuration.auth.headers.length; i += 1) {
                    if (props.configuration.auth.headers[i].key === 'Authorization') {
                        props.configuration.auth.headers.splice(i, 1);
                        break;
                    }
                }
                return;
            }
            const authenticationHeader = props.configuration.auth.headers.find(
                (header: Header) => header.key === 'Authorization',
            );
            if (authenticationHeader === undefined) {
                props.configuration.auth.headers.push({
                    key: 'Authorization',
                    value: `${props.configuration.auth.tokenType} ${props.configuration.auth.accessToken}`,
                    sensitive: true,
                });
                props.configuration.params.headerTags.push({
                    value: '',
                    tags: [],
                });
            } else {
                authenticationHeader.value = `${props.configuration.auth.tokenType} ${props.configuration.auth.accessToken}`;
            }
        };

        const changeMethod = () => {
            props.configuration.pagination.parameters.forEach((param: any) => {
                if (param.key.type === 'body') {
                    param.key.type = 'query'; // eslint-disable-line no-param-reassign
                }
            });
            paginationParameters.value.forEach((param: any) => {
                if (param.key.type === 'body') {
                    param.key.type = 'query'; // eslint-disable-line no-param-reassign
                }
            });
            props.configuration.params.parameters.forEach((param: any, index: number) => {
                if (param.key.type === 'body') {
                    props.configuration.params.parameters.splice(index, 1);
                }
            });
            props.configuration.params.method = newMethod.value; // eslint-disable-line no-param-reassign
            showChangeMethodModal.value = false;
        };

        const doNotChangeMethod = () => {
            props.configuration.params.method = oldMethod.value; // eslint-disable-line no-param-reassign
            showChangeMethodModal.value = false;
        };

        const confirmChangeMethod = (event: any) => {
            oldMethod.value = props.configuration.params.method;
            newMethod.value = event.target.value;
            if (newMethod.value === oldMethod.value || newMethod.value !== 'GET') {
                props.configuration.params.method = newMethod.value; // eslint-disable-line no-param-reassign
                return;
            }
            showChangeMethodModal.value = true;
        };

        const changePaginationType = (type: string) => {
            props.configuration.pagination.parameters.splice(0);
            paginationParameters.value.splice(0);
            props.configuration.pagination.type = type; // eslint-disable-line no-param-reassign
            if (type === 'offset') {
                paginationParameters.value.push(
                    {
                        key: {
                            name: 'limit',
                            type: 'query',
                        },
                        value: {
                            value: 100,
                            type: 'static',
                        },
                        sensitive: false,
                        title: 'Limit',
                        description: 'How many items to include in each page',
                        optional: false,
                    },

                    {
                        key: {
                            name: 'offset',
                            type: 'query',
                        },
                        value: {
                            value: 0,
                            type: 'pagination',
                        },
                        sensitive: false,
                        title: 'Offset',
                        description: 'The starting offset value',
                        optional: false,
                    },
                );
            } else if (type === 'page') {
                paginationParameters.value.push(
                    {
                        key: {
                            name: 'limit',
                            type: 'query',
                        },
                        value: {
                            value: 100,
                            type: 'static',
                        },
                        sensitive: false,
                        title: 'Limit',
                        description: 'How many items to include in each page',
                        optional: true,
                    },

                    {
                        key: {
                            name: 'page',
                            type: 'query',
                        },
                        value: {
                            value: 1,
                            type: 'pagination',
                        },
                        sensitive: false,
                        title: 'Page',
                        description: 'The starting page',
                        optional: false,
                    },
                );
            }
            paginationParameters.value.forEach((param: Param) => {
                props.configuration.pagination.parameters.push(param);
            });
            showChangePaginationModal.value = false;
        };

        const confirmChangePagination = (type: string) => {
            if (type === props.configuration.pagination.type) return;

            newPaginationType.value = type;
            showChangePaginationModal.value = true;
        };

        const addHeaderValue = (newValue: string, id: string) => {
            const index = id.split('-')[1];
            props.configuration.params.headers[index].value = newValue; // eslint-disable-line no-param-reassign
        };

        const resetAuthentication = () => {
            props.configuration.auth.accessToken = null; // eslint-disable-line no-param-reassign
            props.configuration.auth.payload = null; // eslint-disable-line no-param-reassign
            props.configuration.auth.loginTested = false; // eslint-disable-line no-param-reassign
            isAuthenticationConfigurationSaved.value = false;
        };

        const changePaginationParameterType = (type: string, param: Param) => {
            param.key.type = type; // eslint-disable-line no-param-reassign
            if (param.value.type !== 'pagination') {
                if (type === 'body') {
                    param.value.type = 'integer'; // eslint-disable-line no-param-reassign
                } else if (type === 'query') {
                    param.value.type = 'static'; // eslint-disable-line no-param-reassign
                }
            }
        };

        const confirmIgnoreCertificatesModal = (type: string) => {
            alertType.value = type;
            showConfirmIgnoreCertificatesModal.value = true;
        };

        const cancelIgnoreCertificates = () => {
            if (alertType.value === 'login') {
                props.configuration.auth.ignoreCertificates = false; // eslint-disable-line no-param-reassign
            } else if (alertType.value === 'error') {
                props.configuration.params.ignoreCertificates = false; // eslint-disable-line no-param-reassign
            }
            showConfirmIgnoreCertificatesModal.value = false;
        };

        const confirmIgnoreCertificates = () => {
            showConfirmIgnoreCertificatesModal.value = false;
            if (alertType.value === 'login') {
                props.configuration.auth.ignoreCertificates = true; // eslint-disable-line no-param-reassign
                testLogin();
            } else if (alertType.value === 'error') {
                props.configuration.params.ignoreCertificates = true; // eslint-disable-line no-param-reassign
                testAPIConnection();
            }
        };

        const updateSchedules = (updatedSchedules: any) => {
            schedules.value = updatedSchedules;
        };

        const invalidNoOfSchedules = computed(() => {
            if (
                props.configuration.retrieval.type !== 'immediately' &&
                props.configuration.retrieval.type !== 'polling'
            ) {
                if (schedules.value && schedules.value.length) {
                    let noEnabledSchedules = true;
                    for (let i = 0; i < schedules.value.length; i++) {
                        if (schedules.value[i].isEnabled) {
                            noEnabledSchedules = false;
                            break;
                        }
                    }
                    return noEnabledSchedules;
                }
                return true;
            }
            return false;
        });

        watch(
            () => props.configuration.auth.method,
            (newAuthMethod: string) => {
                if (newAuthMethod === 'keycloak') {
                    // eslint-disable-next-line no-param-reassign
                    props.configuration.auth.payload = JSON.stringify(
                        {
                            client_secret: '<ENTER_YOUR_CLIENT_SECRET>', // eslint-disable-line
                            client_id: '<ENTER_YOUR_CLIENT_ID>', // eslint-disable-line
                            username: '<ENTER_YOUR_USERNAME>',
                            password: '<ENTER_YOUR_PASSWORD>',
                            grant_type: 'password', // eslint-disable-line
                        },
                        null,
                        2,
                    );
                }
            },
        );

        return {
            loading,
            urlRef,
            editingURL,
            baseURL,
            parseURL,
            cancelEditingURL,
            invalidLoginBody,
            invalidQueryBody,
            isConfigurationSaved,
            isAuthenticationConfigurationSaved,
            inVault,
            errorAlert,
            loginAlert,
            addHeader,
            configureParam,
            apiValidationRef,
            modifyFinalSample,
            changeAuthenticationMethod,
            changeMethod,
            doNotChangeMethod,
            changePaginationType,
            extractURLParams,
            extractBodyParams,
            finalSample,
            newAuthenticationMethod,
            newPaginationType,
            loginResponse,
            loginResponseKeys,
            removeHeader,
            removeParameter,
            showChangeAuthenticationModal,
            showChangeMethodModal,
            showChangePaginationModal,
            showConfirmEditLoginModal,
            showConfirmIgnoreCertificatesModal,
            confirmIgnoreCertificatesModal,
            confirmIgnoreCertificates,
            cancelIgnoreCertificates,
            showParameterModal,
            testAPIConnection,
            validate,
            testLogin,
            confirmEditLogin,
            editLogin,
            setAuthenticationHeader,
            confirmChangeAuthentication,
            confirmChangeMethod,
            confirmChangePagination,
            addHeaderValue,
            autocompleteTags,
            addHeaderTag,
            resetAuthentication,
            addParam,
            capitalise,
            editParam,
            updateParam,
            valueClasses,
            urlPreview,
            paginationParameters,
            changeIgnoreStatus,
            isDuplicateParam,
            changePaginationParameterType,
            paramChanged,
            focusChoice,
            focusNestedArrays,
            isSampleArray,
            focusSelectedNestedArrayBasePath,
            focusSelectedObjectsFromOutside,
            separator,
            additionalPaths,
            additionalDataKeyValue,
            executeApi,
            hasResponseData,
            retrievalTypes,
            invalidNoOfSchedules,
            updateSchedules,
            schedules,
            tomorrow,
            enableElements,
        };
    },
});
