




















































































































import * as R from 'ramda';
import { TrashIcon, ClockIcon, DocumentAddIcon } from '@vue-hero-icons/outline';
import { defineComponent, ref, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { StatusCode } from '@/modules/data-checkin/constants';
import { ErrorCodes } from '@/app/constants/error-codes';
import { DataCheckinJobStep } from '../types';

export default defineComponent({
    name: 'JobOverview',
    components: { ExclamationIcon, TrashIcon, ClockIcon, DocumentAddIcon },
    props: {
        job: {
            type: Object,
            required: true,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root }) {
        const isExpanded = ref(props.expanded);
        const expand = () => {
            isExpanded.value = !isExpanded.value;
        };

        const options = ref(['edit', 'delete']);

        // add execution history to jobs that have a workflow id
        if (props.job.workflowId) {
            options.value.push('history');
        }

        const harvester = props.job.dataCheckinJobSteps.find((step: DataCheckinJobStep) => step.order === 0);
        const loader = props.job.dataCheckinJobSteps.find((step: DataCheckinJobStep) => step.order === 100);
        if (
            harvester.configuration &&
            harvester.configuration.source === 'file' &&
            loader.status === StatusCode.Completed
        ) {
            options.value.splice(1, 0, 'appendData');
        }

        const appendData = () => {
            root.$router.push({ name: 'harvester', params: { id: props.job.id } });
        };

        const orderedSteps = computed(() => {
            return R.sort(R.ascend<any>(R.prop('order')), props.job.dataCheckinJobSteps);
        });

        const statusStep: any = computed(() => {
            for (let idx = 0; idx < orderedSteps.value.length; idx += 1) {
                if (
                    orderedSteps.value[idx].status === StatusCode.Configuration ||
                    orderedSteps.value[idx].status === StatusCode.Update
                ) {
                    return orderedSteps.value[idx];
                }
            }

            for (let idx = 0; idx < orderedSteps.value.length; idx += 1) {
                if (orderedSteps.value[idx].status !== StatusCode.Completed) {
                    return orderedSteps.value[idx];
                }
            }
            return orderedSteps.value[orderedSteps.value.length - 1];
        });

        const statusMessage = computed(() => {
            if (statusStep.value.status === StatusCode.Completed) return 'completed';
            if (statusStep.value.status === StatusCode.Deprecated) return 'deprecated';

            return `${statusStep.value.status}`;
        });

        const statusClass = computed(() => {
            switch (statusStep.value.status) {
                case StatusCode.Failed:
                    return 'text-red-700 bg-red-200';
                case StatusCode.Cancelled:
                    return 'text-orange-700 bg-orange-200';
                case StatusCode.Completed:
                    return 'text-green-700 bg-green-200';
                case StatusCode.Queued:
                    return 'text-purple-700 bg-purple-200';
                case StatusCode.Running:
                    return 'text-blue-700 bg-blue-200';
                case StatusCode.Idle:
                    return 'text-teal-700 bg-teal-200';
                case StatusCode.Deprecated:
                    return 'text-red-700 bg-red-100';
                case StatusCode.Configuration:
                default:
                    return 'text-neutral-700 bg-neutral-300';
            }
        });

        const errorMessage = computed(() =>
            statusStep.value.message && statusStep.value.message.errorCode
                ? ErrorCodes[statusStep.value.message.errorCode] ||
                  `Unknown Error Code: ${statusStep.value.message.errorCode}`
                : null,
        );

        return {
            isExpanded,
            dayjs,
            expand,
            options,
            statusMessage,
            statusClass,
            StatusCode,
            orderedSteps,
            statusStep,
            errorMessage,
            appendData,
        };
    },
});
