




















































































































































































































































import * as R from 'ramda';
import { defineComponent, ref, watch, computed, onMounted, provide } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@vue-composable/axios';
import { useQuery, useResult, useErrors } from '@/app/composable';
import { ConfirmModal, TwButton, WizardTabs, HtmlModal, SvgImage } from '@/app/components';
import { Status } from '@/modules/data-model/constants';
import { StatusCode } from '@/modules/data-checkin/constants';
import MappingConfiguration from './Configuration.vue';
import MappingInfo from './Info.vue';
import MappingReview from './Review.vue';
import GET_JOB from '../../graphql/getJob.graphql';
import { MappingConfig } from './mapping.types';
import { JobsAPI, ModelAPI } from '../../api';
import { validateConfiguration, useStep, useMapping } from '../../composable';
import StepCompletionModal from '../../components/StepCompletionModal.vue';

export default defineComponent({
    name: 'Mapping',
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    components: {
        ConfirmModal,
        MappingConfiguration,
        MappingInfo,
        OrbitSpinner,
        TwButton,
        WizardTabs,
        MappingReview,
        StepCompletionModal,
        HtmlModal,
        SvgImage,
    },
    setup(props, { root }) {
        const { loading, error, exec } = useAxios(true);
        const jobId = parseInt(`${props.id}`, 10);
        const steps = ref([{ title: 'Info' }, { title: 'Configuration' }, { title: 'Review and Confirmation' }]);
        const mappingRef = ref<HTMLElement | null>(null);
        const activeTab = ref(0);
        provide('activeTab', activeTab);
        const hasChanges = ref<boolean>(false);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const nextStep = ref<any>(null);
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        // Fetch job information
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { loading: jobLoading, error: jobError, result, onError, refetch } = useQuery(GET_JOB, { id: jobId });
        onError(checkGQLAuthentication);
        const job = useResult(result, null, (data: any) => data.job);
        const loadingFinalization = ref<boolean>(false);

        // Domains and Concepts
        const domains = ref<any>([]);
        const concepts = ref<any>(null);
        const model = ref<any>(null);
        const flatModel = ref<any>(null);
        exec(ModelAPI.domains()).then((res: any) => {
            domains.value = R.sort(R.ascend(R.prop('name') as any), res.data);
        });

        // Fetch mapping configuration
        const mapping = ref<any>(null);
        const {
            isConfigEmpty,
            isFinalized,
            getNextStep,
            updateAssetAfterFailedStep,
            checkStepRestartEligibility,
            canRestart,
        } = useStep(mapping, job);

        // Default (empty) configuration
        const configuration = ref<MappingConfig>({
            domain: null,
            standard: null,
            concept: null,
            multiple: false,
            basePath: null,
            versions: {
                editor: '1.1.0',
                transformationEngine: process.env.VUE_APP_TRANSFORMER_VERSION as string,
                model: null,
                predictionEngine: null,
            },
            fields: [],
            customizedConcepts: {},
        });

        const stats = ref<any>(null);
        const message = ref<any>(null);

        const refresh = () => {
            exec(JobsAPI.getStep(jobId, 'mapping')).then((res: any) => {
                mapping.value = res.data;
                if (!isConfigEmpty(res.data.configuration)) {
                    configuration.value = R.clone(res.data.configuration);
                }
                if (mapping.value.message) {
                    message.value = mapping.value.message;
                }
                if (mapping.value.stats) {
                    stats.value = mapping.value.stats;
                }
                if (job.value) {
                    configuration.value.multiple = job.value.config?.multiple || false;
                    configuration.value.basePath = job.value.config?.basePath || null;
                }
                checkStepRestartEligibility();
                refetch(); // refetch job
            });
        };

        const rootConcept = computed(() => {
            if (configuration.value && configuration.value.concept && model.value) {
                return model.value.children.filter((obj: any) => obj.id === configuration.value.concept?.id)[0];
            }
            return null;
        });

        const isLocked = computed<boolean>(
            () => mapping.value && mapping.value.configuration && !!mapping.value.configuration.domain,
        );

        /**
         * Detects if we need to inform the user to do a mapping upgrade
         */
        const mappingNeedsUpgrade = computed(() => {
            if (!isLocked.value || isFinalized.value) {
                return false;
            }
            if (isLocked.value && !isFinalized.value && configuration.value?.domain?.id) {
                for (let d = 0; d < domains.value.length; d++) {
                    const domain = domains.value[d];
                    if (domain.id === configuration.value.domain.id) {
                        return false;
                    }
                }
            }
            // if no matching domain is found in the iteration above
            // an upgrade is needed
            return true;
        });

        watch(
            () => configuration.value?.domain,
            async (domain: any) => {
                if (domain && configuration.value) {
                    if (!isLocked.value) {
                        configuration.value.concept = null;
                        configuration.value.standard = null;
                    }
                    await exec(ModelAPI.concepts(domain.id, Status.Stable)).then((res: any) => {
                        concepts.value = R.sort(R.ascend(R.prop('name') as any), res.data);
                    });
                }
            },
        );

        watch(
            () => isFinalized.value,
            async (finalized) => {
                if (finalized) {
                    // Retrieve model and flatmodel (only once)
                    if (configuration.value?.domain && (model.value === null || flatModel.value === null)) {
                        await exec(ModelAPI.completeModel(configuration.value?.domain.id)).then((res: any) => {
                            model.value = res.data;
                        });
                        await exec(ModelAPI.conceptNames(configuration.value?.domain.id)).then((res: any) => {
                            flatModel.value = res.data;
                        });
                    }
                }
            },
        );

        const mappedConceptsExist = computed(() => {
            const unmappedConcepts = configuration.value.fields.filter((obj: any) => {
                return !('target' in obj && 'id' in obj.target && obj.target.id);
            });
            return unmappedConcepts.length !== configuration.value.fields.length;
        });

        const showConfirmModal = ref(false);
        const allowNext = computed(() => !!configuration.value?.domain && !!configuration.value?.concept);
        const next = async () => {
            if (activeTab.value === 0) {
                // If is not locked/saved, then confirm and save
                if (!isLocked.value) {
                    showConfirmModal.value = true;
                    return;
                }
                // Retrieve model and flatmodel (only once)
                if (configuration.value?.domain && (model.value === null || flatModel.value === null)) {
                    await exec(ModelAPI.completeModel(configuration.value?.domain.id)).then((res: any) => {
                        model.value = res.data;
                    });
                    await exec(ModelAPI.conceptNames(configuration.value?.domain.id)).then((res: any) => {
                        flatModel.value = res.data;
                    });
                }
            }

            if (activeTab.value !== 0 && !mappedConceptsExist.value) {
                (root as any).$toastr.e('At least one field must be mapped', 'Failed');
                return;
            }

            // Move to next tab
            activeTab.value += 1;
        };
        const previous = () => {
            activeTab.value -= 1;
        };

        const validate = () => {
            validateConfiguration(configuration);
        };

        const save = async () => {
            try {
                validate();

                const payload: any = {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                };
                if (mapping.value.status === StatusCode.Update) {
                    payload.message = message.value;
                }

                await exec(JobsAPI.updateStep(mapping.value.id, payload));
                (root as any).$toastr.s('Mapping configuration saved successfully', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving mapping configuration failed', 'Failed');
                hasChanges.value = true;
            }
        };

        const restartStep = async () => {
            try {
                await exec(JobsAPI.restartStep(mapping.value.id)).then((res: any) => {
                    mapping.value = res.data;
                });

                activeTab.value = 1;
                (root as any).$toastr.s(
                    'The configuration of the mapping step is now available for updates.',
                    'Success',
                );
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the mapping step failed', 'Failed');
            }
        };

        const saveAndProceed = () => {
            exec(
                JobsAPI.updateStep(mapping.value.id, {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                }),
            )
                .then(async (res: any) => {
                    mapping.value = res.data;
                    showConfirmModal.value = false;
                    hasChanges.value = false;
                    await next();
                })
                .catch(() => {
                    (root as any).$toastr.e('Saving mapping configuration automatically failed', 'Failed');
                });
        };

        const isValid = computed<boolean>(
            () => configuration.value.fields.filter((obj: any) => obj.temp && obj.temp.invalid).length === 0,
        );

        const finalize = () => {
            validateConfiguration(configuration);
            if (isValid.value) {
                loadingFinalization.value = true;
                exec(JobsAPI.updateStep(mapping.value.id, { configuration: configuration.value })).then(() => {
                    getNextStep().then(async (stepTypeResponse: any) => {
                        nextStep.value = stepTypeResponse;

                        /**
                         * If loader step (order = 100) has a different status than "configuration",
                         * it means that the Asset has already been created
                         */
                        if (
                            mapping.value.status === StatusCode.Update &&
                            nextStep.value.order === 100 &&
                            nextStep.value.status !== StatusCode.Configuration
                        ) {
                            if (job.value.asset && job.value.asset.id) {
                                await updateAssetAfterFailedStep(job.value);
                                await exec(JobsAPI.finalize(mapping.value.id));
                                restartedStep.value = true;
                            } else {
                                (root as any).$toastr.e(
                                    'Failed finalizing revised Mapping step due to an error',
                                    'Failed',
                                );
                            }
                        } else {
                            await exec(JobsAPI.finalize(mapping.value.id));
                            showFinalizeModal.value = true;
                        }
                        loadingFinalization.value = false;
                    });
                });
            }
        };

        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs' });
        };

        const domainChanged = () => {
            mapping.value.standard = null;
            mapping.value.concept = null;
        };

        const showWarningAboutDeprecatedFields = (deprecatedNames: string[]) => {
            if (deprecatedNames.length === 1) {
                (root as any).$toastr.w(
                    `In the latest version of the model you are using, field '${deprecatedNames[0]}' has been deprecated`,
                    'Warning',
                );
            } else if (deprecatedNames.length > 1) {
                (root as any).$toastr.w(
                    `In the latest version of the model you are using, fields '${deprecatedNames.join(
                        ', ',
                    )}' have been deprecated`,
                    'Warning',
                );
            }
        };

        const upgradeMapping = async () => {
            try {
                if (configuration.value.concept && configuration.value.domain) {
                    // fetching original concept
                    const concept = await exec(ModelAPI.conceptTree(configuration.value.concept.id)).then(
                        (res: any) => {
                            return res.data;
                        },
                    );
                    // retrieves a map where the key is the old id and the value is the new concept
                    const idMappings = await exec(ModelAPI.domainLatestMapping(configuration.value.domain.id)).then(
                        (res: any) => {
                            return res.data;
                        },
                    );

                    // get upgraded mapping
                    const { migrate } = useMapping(job.value.sample, idMappings[concept.id]);
                    const upgradedMapping = migrate(
                        configuration.value.domain,
                        configuration.value.concept,
                        configuration.value.fields,
                        idMappings,
                        idMappings[concept.id].status === Status.Deprecated,
                    );

                    configuration.value.fields = upgradedMapping.fields;
                    configuration.value.domain = upgradedMapping.domain;
                    configuration.value.concept = upgradedMapping.concept;

                    validate();
                    await exec(
                        JobsAPI.updateStep(mapping.value.id, {
                            configuration: configuration.value,
                            serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                        }),
                    );

                    if (idMappings[concept.id].status !== Status.Deprecated) {
                        (root as any).$toastr.s(
                            'Mapping upgraded to the latest version of the model successfully',
                            'Success',
                        );
                        showWarningAboutDeprecatedFields(upgradedMapping.deprecatedFields);
                    } else {
                        (root as any).$toastr.w(
                            `Mapping reset because concept '${concept.name}' is deprecated in the latest version of the model`,
                            'Warning',
                        );
                    }
                    hasChanges.value = false;
                    refresh();
                } else {
                    throw new Error('Concept not defined!');
                }
            } catch (e) {
                (root as any).$toastr.e('Upgrading mapping to the latest version of the model failed', 'Failed');
                hasChanges.value = true;
            }
        };

        onMounted(async () => {
            if (!isConfigEmpty(mapping.value)) await next();
        });

        const deprecateFields = async (deprecatedConceptIds: number[]) => {
            const deprecatedNames: string[] = [];
            const { initEmptyField } = useMapping(job.value.sample, rootConcept.value);
            for (let f = 0; f < configuration.value.fields.length; f++) {
                const field = configuration.value.fields[f];
                if (field.target.id && field.target.title && deprecatedConceptIds.includes(field.target.id)) {
                    deprecatedNames.push(field.target.title);
                    configuration.value.fields[f] = initEmptyField(field.source);
                }
            }
            showWarningAboutDeprecatedFields(deprecatedNames);

            await exec(
                JobsAPI.updateStep(mapping.value.id, {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                }),
            );
        };

        /**
         * Remove any stats/ failed transformations related to the mapping the user has just modified or removed
         */
        const removeInvalidTransformation = (sourceId: any) => {
            if (message.value && message.value.failedTransformations && sourceId) {
                let fieldId: any = null;
                Object.entries(message.value.stats).forEach((entry: any) => {
                    const [key, value]: any = entry;
                    if (sourceId === value.source_id) {
                        fieldId = key;
                    }
                });
                if (fieldId) {
                    message.value.stats = Object.keys(message.value.stats).reduce((acc: any, statKey: any) => {
                        if (statKey !== fieldId) {
                            acc[statKey] = message.value.stats[statKey];
                        }
                        return acc;
                    }, {});

                    message.value.failedTransformations = Object.keys(message.value.failedTransformations).reduce(
                        (acc: any, failedTransformationKey: any) => {
                            if (failedTransformationKey !== fieldId) {
                                acc[failedTransformationKey] =
                                    message.value.failedTransformations[failedTransformationKey];
                            }
                            return acc;
                        },
                        {},
                    );
                }
            }
        };

        // Remove 'modified' property and invalid transformation (if exists) of a removed mapping
        const clearMapping = (sourceId: any) => {
            if (sourceId) {
                const mappingExists: any = configuration.value.fields.filter(
                    (field: any) => field.source.id === sourceId,
                );
                if (mappingExists && mappingExists[0] && 'modified' in mappingExists[0].temp) {
                    delete mappingExists[0].temp.modified;
                }
                removeInvalidTransformation(sourceId);
            }
        };

        /**
         * Deletes the 'modified' property from the previous/ current mapping configurations
         * in order to be able to properly compare them to identify any changes
         */
        const removePropertyFromMapping = (fieldId: any, config: any) => {
            // remove the 'modified' property from the already existing mapping
            const mappingExists: any = config.fields.filter((field: any) => field.source.id === fieldId);

            const clonedMapping = R.clone(mappingExists[0]);
            if (clonedMapping && 'modified' in clonedMapping.temp) {
                delete clonedMapping.temp.modified;
            }

            return clonedMapping;
        };

        const revisedMapping = (fieldId: any) => {
            if (fieldId && mapping.value && mapping.value.status === StatusCode.Update) {
                // remove the 'modified' property from the already existing mapping
                const clonedMappingAlreadyExists = removePropertyFromMapping(fieldId, mapping.value.configuration);
                // remove the 'modified' property from the new mapping
                const clonedNewlyAddedMapping = removePropertyFromMapping(fieldId, configuration.value);

                const hasDifference =
                    JSON.stringify(clonedNewlyAddedMapping) !== JSON.stringify(clonedMappingAlreadyExists);

                /**
                 * - Add the 'modified' property only if this mapping already existed and was saved and there is a change i.e. in the mapping details
                 * - If the user changes the field and then changes it back to the old value, then it will stay as 'modified'
                 */
                if (
                    clonedMappingAlreadyExists &&
                    clonedMappingAlreadyExists.target.id &&
                    clonedMappingAlreadyExists.target.id === clonedNewlyAddedMapping.target.id &&
                    hasDifference
                ) {
                    const idx: any = configuration.value.fields.findIndex((field: any) => field.source.id === fieldId);

                    if (idx >= 0 && configuration.value.fields[idx].temp) {
                        configuration.value.fields[idx].temp.modified = true;
                    }
                    removeInvalidTransformation(fieldId);
                }
            }
            hasChanges.value = true;
        };

        const invalidMappingsFixed = computed(() => {
            if (message.value && message.value.failedTransformations) {
                return !Object.keys(message.value.failedTransformations).length;
            }
            return true;
        });

        refresh();

        const pageLoading = computed(() => {
            return loadingFinalization.value || loading.value || jobLoading.value;
        });

        return {
            activeTab,
            allowNext,
            cancel,
            concepts,
            configuration,
            message,
            stats,
            domainChanged,
            domains,
            error,
            finalize,
            flatModel,
            hasChanges,
            isFinalized,
            isLocked,
            isValid,
            job,
            jobError,
            jobLoading,
            loading,
            mapping,
            mappingRef,
            model,
            next,
            previous,
            save,
            saveAndProceed,
            showConfirmModal,
            steps,
            validate,
            showFinalizeModal,
            nextStep,
            mappedConceptsExist,
            isMacOS,
            mappingNeedsUpgrade,
            upgradeMapping,
            deprecateFields,
            StatusCode,
            restartStep,
            canRestart,
            revisedMapping,
            // hasDifferences,
            clearMapping,
            invalidMappingsFixed,
            restartedStep,
            pageLoading,
        };
    },
});
