
































































































































import * as R from 'ramda';
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import Draggable from 'vuedraggable';
import ViewConstraint from './constraints/ViewConstraint.vue';
import EditConstraint from './constraints/EditConstraint.vue';
import { Constraint } from './cleaning.types';
import { constraintOptions } from '../../constants';

export default defineComponent({
    name: 'CleaningConstraints',
    components: { Draggable, ViewConstraint, EditConstraint },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        selectedFields: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const constraintId = computed(() => {
            let constraints: number[] = [];
            props.configuration.fields.forEach((field: any) => {
                constraints = constraints.concat(field.constraints);
            });
            constraints = R.uniq(constraints.map((constraint: any) => constraint.id));
            if (constraints.length === 0) {
                return 1;
            }
            return Math.max(...constraints) + 1;
        });

        const isEmpty = computed(() => props.selectedFields.length === 0);
        const editIndex = ref<number>(-1);
        const selectedFieldsTypes = computed(() => R.uniq(props.selectedFields.map((field: any) => field.type)));
        // get fields that have the same type as the selected fields but are not selected
        const referencedFields = computed(() =>
            props.configuration.fields.filter(
                (configurationField: any) =>
                    !props.selectedFields.find((field: any) => field.name === configurationField.name) &&
                    !configurationField.multiple &&
                    selectedFieldsTypes.value.includes(configurationField.type),
            ),
        );
        const processedConstraints = ref<Constraint[]>([]);
        const selectedFieldsConstraints = ref<Constraint[]>([]);

        const alreadyDefinedConstraintTypes = computed(() => {
            let array: any[] = [];
            props.selectedFields.forEach((field: any) => {
                array = array.concat(field.constraints);
            });
            return R.uniq(array.map((constraint: any) => constraint.type));
        });

        const areSelectedFieldsMultiple = computed(() => props.selectedFields.some((field: any) => field.multiple));

        watch(
            () => props.selectedFields,
            (fields: any) => {
                editIndex.value = -1;
                let array: any[] = [];
                fields.forEach((field: any) => {
                    array = array.concat(field.constraints);
                });
                // get only the common constraints from the selected fields
                if (fields.length > 1) {
                    const finalArray: any[] = [];
                    const ids = R.uniq(array.map((constraint) => constraint.referenceId));
                    ids.forEach((id: number) => {
                        const count = array.filter((constraint: any) => constraint.referenceId === id).length;
                        if (count === fields.length) {
                            finalArray.push(array.find((constraint: any) => constraint.referenceId === id));
                        }
                    });
                    array = finalArray;
                }
                processedConstraints.value = array;
                selectedFieldsConstraints.value = R.clone(array);
                emit('change-edit-mode', null);
            },
        );

        const addConstraint = () => {
            processedConstraints.value.push({
                id: constraintId.value,
                referenceId: constraintId.value,
                type: null,
                details: null,
                outliersRule: {
                    type: null,
                    replaceValue: null,
                    secondaryRule: null,
                },
            });
            editIndex.value = processedConstraints.value.length - 1;
        };

        const cancelAddition = () => {
            processedConstraints.value.pop();
            editIndex.value = -1;
            emit('change-edit-mode', null);
        };

        const cancelUpdate = () => {
            editIndex.value = -1;
            emit('change-edit-mode', null);
        };

        const saveConstraint = (mode: string, index: number) => {
            let count = 0;
            props.selectedFields.forEach((selectedField: any) => {
                const constraint = processedConstraints.value[index];
                if (mode === 'create') {
                    if (count !== 0) {
                        constraint.id += 1;
                    }
                    count += 1;
                    selectedField.constraints.push(R.clone(constraint));
                } else {
                    const idx = selectedField.constraints.findIndex(
                        (field: any) => field.referenceId === constraint.referenceId,
                    );
                    if (mode === 'update') {
                        constraint.id = selectedField.constraints[idx].id;
                        // eslint-disable-next-line no-param-reassign
                        selectedField.constraints[idx] = R.clone(constraint);
                        // if a common constraint is updated only for some of the fields then remove it from common
                        props.configuration.fields.forEach((field: any) => {
                            const constr = field.constraints.find(
                                (con: any) => con.referenceId === constraint.referenceId,
                            );
                            if (constr && !props.selectedFields.find((f: any) => f.id === field.id)) {
                                if (constr.referenceId === constr.id) {
                                    constr.referenceId += 1;
                                } else {
                                    constr.referenceId = constr.id;
                                }
                            }
                        });
                    } else if (mode === 'delete') {
                        selectedField.constraints.splice(idx, 1);
                    }
                }
            });
            if (mode === 'delete') {
                processedConstraints.value.splice(index, 1);
            }
            editIndex.value = -1;
            selectedFieldsConstraints.value = R.clone(processedConstraints.value);
            emit('save-constraints');
        };

        const constraintMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            props.selectedFields.forEach((selectedField: any) => {
                const oldIdx = selectedField.constraints.findIndex(
                    (field: any) => field.referenceId === processedConstraints.value[newIndex].referenceId,
                );
                const newIdx = selectedField.constraints.findIndex(
                    (field: any) => field.referenceId === processedConstraints.value[oldIndex].referenceId,
                );
                // eslint-disable-next-line no-param-reassign
                selectedField.constraints = R.move(oldIdx, newIdx, selectedField.constraints);
                emit('save-constraints');
            });
        };

        const setConstraintOptions = () => {
            let options = constraintOptions;
            // remove FOREIGN_KEY and CROSS_FIELD constraints if there are no referenced fields or if field is multiple
            if (referencedFields.value.length === 0 || areSelectedFieldsMultiple.value) {
                options = options.filter((option: any) => option.id !== 'FOREIGN_KEY' && option.id !== 'CROSS_FIELD');
            }
            // return only MANDATORY and UNIQUE constraints if selected fields do not have the same data type
            if (selectedFieldsTypes.value.length > 1) {
                return options.filter((option: any) => option.id === 'MANDATORY' || option.id === 'UNIQUE');
            }
            // remove RANGE constraint for string selected fields
            if (selectedFieldsTypes.value.includes('string')) {
                return options.filter((option: any) => option.id !== 'RANGE');
            }
            // return only MANDATORY, FOREIGN_KEY and CROSS_FIELD constraints for boolean selected fields
            if (selectedFieldsTypes.value.includes('boolean')) {
                return options.filter(
                    (option: any) =>
                        option.id === 'MANDATORY' || option.id === 'FOREIGN_KEY' || option.id === 'CROSS_FIELD',
                );
            }
            return options;
        };

        const canAddConstraint = computed(() => {
            let canAdd = false;
            let options = setConstraintOptions();
            options = options.map((constraint: any) => constraint.id);
            options.forEach((option: any) => {
                if (!alreadyDefinedConstraintTypes.value.includes(option)) {
                    canAdd = true;
                }
            });
            return canAdd;
        });

        return {
            isEmpty,
            saveConstraint,
            processedConstraints,
            selectedFieldsConstraints,
            editIndex,
            selectedFieldsTypes,
            referencedFields,
            addConstraint,
            constraintMoved,
            cancelAddition,
            cancelUpdate,
            setConstraintOptions,
            alreadyDefinedConstraintTypes,
            canAddConstraint,
            emit,
        };
    },
});
